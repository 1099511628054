import { useEffect } from "react"
import Marquee from "react-fast-marquee"
import { TextMove, Spacer } from './styles/Containers'
import map from '../images/map_laptop.png'
import Aos from 'aos'
import 'aos/dist/aos.css'



const LocationMap = () => {



    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])

    return (
        <div style={{height:'130vh', position:'relative', overflow:'hidden', zIndex:'0', backgroundColor:'rgb(244,238,224)'}} id='where'>
            <TextMove pc='background-position:center -3vh;' mob='background-position:center -1.2vh;' style={{top:'0', position:"absolute", top:"-10px"}}>
                <Marquee gradient={false} speed={100} >
                    <span style={{fontSize:'0.18rem', lineHeight:'0.35rem', fontFamily:'Baskerville'}}>
                    ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi?&nbsp;
                    </span>
                </Marquee>
            </TextMove>
            <Spacer space='5vh'/>
            <a href='https://maps.app.goo.gl/va5jCDSNcbdHHJvQ6'>
                <div style={{height:'125vh', backgroundImage:`url(${map})`, backgroundSize:'cover', backgroundPositionY:'-10vh'}}>
                </div>
            </a>
            <TextMove mob='background-position:center 0.1vh;' style={{bottom:'0', position:"absolute", bottom:"-10px"}}>
                <Marquee gradient={false} speed={100} direction="right" >
                    <span style={{fontSize:'0.18rem', lineHeight:'0.35rem', fontFamily:'Baskerville'}}>
                    ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi?&nbsp;
                    </span>
                </Marquee>
            </TextMove>
        </div>
    )
}

export default LocationMap
