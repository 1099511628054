import { useState } from 'react'
import { MenuBox, TextMove } from '../../components/styles/Containers'
import { WelcomeText, TextS } from '../../components/styles/Text'
import { BtnFoodM } from '../../components/styles/Forms'
import { Spacer } from '../../components/styles/Containers'
import BurgerGrid from '../../components/Mobile/BurgerGrid'
import SaladGrid from '../../components/Mobile/SaladGrid'
import MainMenu from '../../components/Mobile/MainMenu'
import Footer from '../../components/Mobile/Footer'
import AnimatedPages from '../../components/AnimatedPages'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import Marquee from 'react-fast-marquee'

const Hungry = () => {

    const [ burger, setBurger] = useState(true)
    const [ salad, setSalad] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])
    
    return (
        <AnimatedPages>
            <MainMenu />
            <MenuBox style={{paddingLeft:0, marginLeft:0}}>
                <WelcomeText style={{fontSize:'6vh', lineHeight:'1.1'}}>Best Burgers in Town!</WelcomeText>
                <Spacer space='2vh' />
                <TextS>Creamos <b>burgers</b> y comida perfecta para un día de surf en <b>La Barceloneta</b> desde 2012 </TextS>
                <Spacer space='5vh' />
                <div style={{display:'flex', gap:'5vw'}}>
                    <BtnFoodM onClick={() => {setBurger(true); setSalad(false)}}>Burgers</BtnFoodM>
                    <BtnFoodM onClick={() => {setBurger(false); setSalad(true)}}>Salads & Co</BtnFoodM>
                </div>
                <Spacer space='5vh'/>
                {burger && <BurgerGrid />}
                {salad && <SaladGrid />}
                <Spacer space='10vh'/>
                <TextS>Toca Bajar esta comida con algo fresquito...</TextS>
                <Spacer space='2vh'/>
                <div style={{display:'flex', gap:'5vw'}}>
                        <Link to='/thirsty/cocktails'><BtnFoodM>Cocktails</BtnFoodM></Link>
                        <Link  to='/thirsty/refrescos'><BtnFoodM>Refrescos</BtnFoodM></Link>
                </div>
                <TextMove style={{bottom:'0', position:'absolute', bottom:"-10px"}}>
                    <Marquee gradient={false} speed={100} direction="right" >
                        <span style={{fontSize:'0.2rem', lineHeight:'0.3rem', fontFamily:'Baskerville'}}>
                        ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi?&nbsp;
                        </span>
                    </Marquee>
                </TextMove>
            </MenuBox>
            <Footer />
        </AnimatedPages>
    )
}

export default Hungry
