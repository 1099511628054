import { relative } from 'path-browserify'
import React from 'react'
import { Link } from 'react-router-dom'
import { CenterDiv, Spacer } from '../../components/styles/Containers'
import { BtnFood } from '../../components/styles/Forms'
import { FText, TextS, TinderInfo } from '../../components/styles/Text'

const Benefits = ({enbed}) => {
  return (
    <CenterDiv style={enbed ? {height:"100%", width:"100%"} : {height:"100vh", width:"100vw"}}>
      {
        !enbed && <a href='/#home-banner'><img src={window.location.origin + '/media/logo.png'} height='50vh' width='auto' style={{ position:'absolute', top:'3vh', left:'3vh'}}/></a>
      }
        <div style={{width:"80vw", textAlign:"center", fontSize:"80%"}}>
            <FText style={{color:"pink", textAlign:"center", fontSize:"70%", left:0, position:"relative"}}>Benefits you said ??</FText>
            <Spacer space='10vh'/>
            <TinderInfo style={{textAlign:"center"}}>1. Magic Mondays @Makamaka</TinderInfo>
            <TextS style={{lineHeight:"1.2vw"}}>
                Cada mes tenemos una nueva oferta para los amigos durante los lunes!  
                <br /> mandaremos un mailing para avisarles de la oferta + post<br/>
                    Primer mes, con cada comida o cena para dos os regalamos patatas (2 burgers o bowls = unas fries a compartir gratis). <br/><br/>
            </TextS>
            <TinderInfo style={{textAlign:"center"}}>2. Maka shot on the house</TinderInfo>
            <TextS style={{lineHeight:"1vw"}}>For amigos only, with lunch or dinner, 7 days a week. <br/><br/></TextS>
            <TinderInfo style={{textAlign:"center"}}>3. Birthday Madness</TinderInfo>
            <TextS style={{lineHeight:"1vw"}}>All YOU can eat & drink (only for the bday boy/girl)</TextS>

            {
              !enbed && <div style={{width:"100%", textAlign:"center", paddingTop:"2vh"}}>
              <BtnFood>
                <Link to='/friends'>Let's play the game !</Link>
              </BtnFood>
            </div>
            }
        
        </div>
        
    </CenterDiv>
  )
}

export default Benefits