import { useState, useEffect } from "react";

const UseMouse = () => {
      
  const [mousePosition, setMousePosition] = useState({
   x: null,
   y: null
});

useEffect (() => {

    const handle = (e) => {
        setMousePosition({
            x : e.clientX,
            y : e.clientY
        })
    }

    document.addEventListener("mousemove", handle);
    return () => document.removeEventListener("mousemove", handle)
})

return mousePosition
}

export default UseMouse