import React from 'react'
import Advanced from '../../components/Tinder/Advanced'
import AnimatedPages from '../../components/AnimatedPages'
import { TinderInfo } from '../../components/styles/Text'
import { useState } from 'react'
import { MenuBox } from '../../components/styles/Containers'
import Benefits from './Benefits'
import { GrClose } from 'react-icons/gr'

// Make a little delay after card is swiped and then show the gif

export const Tinder = () => {

  const [Benefit, setBenefit] = useState(false)

      return (

      <AnimatedPages>
        <div style={{position:'relative'}}>
          {
            Benefit &&
            <div style={{position:"absolute", zIndex:10, backgroundColor:"#f4eee0", width:"80vw", height:"80vh", border:"1px solid black", margin:"10vh 10vw"}}>
              <GrClose onClick={() => setBenefit(false)} style={{zIndex:10, position:"absolute", top:"1vh", right:"1vh", fontSize:"5vh", cursor:"pointer"}}/>
              <Benefits enbed={true}/>
            </div>
          }
          <TinderInfo onClick={() => setBenefit(true)} style={{color:"pink"}}>
            <a style={{position:'absolute', right:"1vw", top:"7vh", rotate:"10deg", fontSize:"5vh"}}>Benefits you said ?</a>
          </TinderInfo>

          <a href='/#home-banner'><img src={window.location.origin + '/media/logo.png'} height='45vh' width='auto' style={{ position:'absolute', top:'3vh', left:'3vh'}}/></a>
          <Advanced />
        </div>
      </AnimatedPages>
      )
}


export default Tinder
