import React from 'react'
import { EnlargedBox, EnlargedModal, Spacer } from './styles/Containers'
import { FBTag, FBText } from './styles/Text'

const ModalShop = ({img, show, setShow, name, tag}) => {
  return (
    <EnlargedModal style={{zIndex:1000}} /*onClick={() => setShow(!show)}*/>

        <div style={{position:'relative'}}>
            {/* <img onClick={() => setShow(!show)} src={window.location.origin + '/media/general/cross.png'}  height='5%' width='auto' style={{position:'absolute', top:'3vh', right:'1.5vw', zIndex:'20'}} /> */}
            <EnlargedBox src={img} style={{zIndex:'0'}}>
            <img src="/media/general/cross_white.png" onClick={() => setShow(!show)} style={{position:"absolute", top:20, right:20, height:"4vh", color:"white", cursor:"pointer"}}/>
                <div>
                    <FBText>{name}</FBText>
                    <br/>
                    <Spacer space='2vh'/>
                    <FBTag>{tag}</FBTag>                             
                </div>
            </EnlargedBox>
        </div>
    </EnlargedModal>
  )
}

export default ModalShop