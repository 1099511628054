import { useState } from "react"
import { MenuImgM, EnlargedBox} from "../styles/Containers"
import { Spacer } from "../styles/Containers"
import { FBText, FBTag } from "../styles/Text"


const SaladGrid = () => {

    const [show, setShow] = useState(false)
    const [img, setImg] = useState('')
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')

    const Salads = [
        {
            name: 'James Brownie',
            tagline: 'Sex Machine!',
            img:'/media/salads/james_brownie.png',
            angle:'rotate(2.23deg)'
        },
        {
            name: 'The PlayBoy Veggie',
            tagline: 'Build your six pack',
            img:'/media/salads/playboy_veggie.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Acapulco Dreamin Tortilla Chips',
            tagline: "Don't call me nachos!",
            img:'/media/salads/acapulco_dreamin.png',
            angle:'rotate(0.23deg)'
        },
        {
            name: 'Caribbean Chicken Salad',
            tagline: 'Build your six pack',
            img:'/media/salads/caribbean_chicken.png',
            angle:'rotate(0.23deg)'
        },
        {
            name: 'Classic Fries',
            tagline: 'Fries before guys!',
            img:'/media/salads/classic_fries.png',
            angle:'rotate(-2.23deg)'
        },
        {
            name: 'Falafel Sliders',
            tagline: 'Build your six pack',
            img:'/media/salads/falafel_sliders.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Hummus Threesome',
            tagline: 'Build your six pack',
            img:'/media/salads/hummus_threesome.png',
            angle:'rotate(-3.23deg)'
        },
        {
            name: 'Lilikoi Cheesecake',
            tagline: 'Cheesier Than Ever',
            img:'/media/salads/lilikoi_cheesecake.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Parmesan Garlic Fries',
            tagline: 'Fries before guys!',
            img:'/media/salads/parmesan_garlic_fries.png',
            angle:'rotate(1.123deg)'
        },
        {
            name: 'Strawberry Field Forever',
            tagline: 'Let me take you down',
            img:'/media/salads/strawberry_pie.png',
            angle:'rotate(1.56deg)'
        },
        {
            name: 'The Playboy',
            tagline: 'Build your six pack',
            img:'/media/salads/the_playboy.png',
            angle:'rotate(1.56deg)'
        }

    ]


    const enlarge = async (food, e) => {

        


        setName(food.name)
        setTag(food.tagline)
        setImg(food.img)
        setShow(true)
        console.log(e.style)
        
    }

    return (
        <div style={{width:'100vw', padding:'0 5vw', position:'relative' }}>

    

                <MenuImgM src={window.location.origin + Salads[0].img}  alt="1" style={{transform:'rotate(2.23deg)'}} onClick={(target) => enlarge(Salads[0], target)} /> 
                <MenuImgM src={window.location.origin + Salads[1].img} style={{transform:'rotate(0.9deg)'}} onClick={() => enlarge(Salads[1])}/> 
                <MenuImgM src={window.location.origin + Salads[2].img} style={{transform:'rotate(-1.2deg)'}} onClick={() => enlarge(Salads[2])}/> 
 
                


                <MenuImgM src={window.location.origin + Salads[3].img} style={{transform:'rotate(3.23deg)'}} onClick={() => enlarge(Salads[3])}/> 
                <MenuImgM src={window.location.origin + Salads[4].img} style={{transform:'rotate(-1.7deg)'}} onClick={() => enlarge(Salads[4])} /> 
                <MenuImgM src={window.location.origin + Salads[5].img} style={{transform:'rotate(-0.5deg)'}} onClick={() => enlarge(Salads[5])}/> 

            



                <MenuImgM src={window.location.origin + Salads[6].img}  style={{transform:'rotate(1.76deg)'}} onClick={() => enlarge(Salads[6])} /> 
                <MenuImgM src={window.location.origin + Salads[7].img} style={{transform:'rotate(0.34deg)'}} onClick={() => enlarge(Salads[7])} /> 
                <MenuImgM src={window.location.origin + Salads[8].img} style={{transform:'rotate(-3.12.0deg)'}} onClick={() => enlarge(Salads[8])}/>



                <MenuImgM src={window.location.origin + Salads[9].img}  style={{transform:'rotate(1.76deg)'}} onClick={() => enlarge(Salads[9])} /> 
                <MenuImgM src={window.location.origin + Salads[10].img} style={{transform:'rotate(0.34deg)'}} onClick={() => enlarge(Salads[10])} /> 

{/* 
                {show && <div style={{backgroundColor:'rgba(0,0,0,0.1)', height:'100vh', width:'100vw', position:'fixed', zIndex:'2', top:'0%', left:'0%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}} onClick={() => setShow(false)}>

                            <EnlargedBox src={img}>
                                <span style={{position:'absolute',  top:'2vh', right:'1vw', fontSize:'5vh', fontWeight:'bold', cursor:'pointer', color:'white'}} onClick={()=> setShow(!show)}>X</span>
                                <div>
                                    <FBText>{name}</FBText>
                                    <br/>
                                    <Spacer space='2vh'/>
                                    <FBTag>{tag}</FBTag>                             
                                </div>
                            </EnlargedBox>
              
              
                    
                </div>} */}
  
        </div>
    )
}

export default SaladGrid
