import {useEffect} from 'react'
import { CensoredBox, WomanBox, TextMove, TextMoveWhite } from './styles/Containers';
import beauty from '../gifs/beauty.mp3'
import useSound from 'use-sound'
import Marquee from "react-fast-marquee"
import {GlassMagnifier} from "react-image-magnifiers";
import Aos from 'aos'
import 'aos/dist/aos.css'

const Censored = () => {

    const [play, {pause}] = useSound(window.location.origin + beauty, { volume: 1 });

    const playSound = () => {
        play();
    }
    

    const stopSound = () => {
        pause();
    }


    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])
    
    return (
        <CensoredBox onMouseEnter={playSound} onMouseLeave={stopSound}>
            <TextMoveWhite pc='background-position:center -4vh;' mob='background-position:center -1.2vh;' style={{top:'0'}}>
                <Marquee gradient={false} speed={100} >
                    <span style={{fontSize:'0.18rem', lineHeight:'0.25rem', fontFamily:'Baskerville'}}>
                        Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life!&nbsp;
                    </span>
                </Marquee>
            </TextMoveWhite>
          
                <WomanBox data-aos="fade-up">
                    <GlassMagnifier magnifierSize='12%' magnifierBorderSize={1}
                        imageSrc={window.location.origin + '/media/general/naked_woman.jpg'}
                        imageAlt="Example"
                        largeImageSrc={window.location.origin + '/media/general/naked_burger.jpg'}
                    />
                    {/* <img src={window.location.origin + '/media/general/naked_woman.png'} height='100%' width='auto'  /> */}
                </WomanBox>

            <TextMoveWhite tab='background-position:center 0px; bottom:-10px;' mob='background-position:center -10px; bottom:0px;' pc='background-position:center -10px; bottom:25px;' style={{}}>
                <Marquee gradient={false} speed={100} direction="right" >
                    <span style={{fontSize:'0.18rem', lineHeight:'0.35rem', fontFamily:'Baskerville'}}>
                        Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life!&nbsp;
                    </span>
                </Marquee>
            </TextMoveWhite>
        </CensoredBox>
    )

}

export default Censored
