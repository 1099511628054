import { useState, useEffect } from 'react'
import useSound from 'use-sound';
import { BoxMove, TextMove } from './styles/Containers';
import Marquee from "react-fast-marquee"
import SoundWord from './SoundWord';
import SoundSticker from './SoundSticker';
import { WelcomeText, SoundText } from './styles/Text'
import { WelcomeBox, Stickers } from './styles/Containers'
import tira_black from "../images/TIRA_papel_02.png"
import UseMouse from './UseMouse'
import Aos from 'aos'
import 'aos/dist/aos.css'


// Gifs
import all from '../gifs/all.gif'
import beach from '../gifs/beach.gif'
import burger from '../gifs/burger.gif'
import cafe from '../gifs/cafe.gif'
import makamaka from '../gifs/makamaka2.gif'
import to from '../gifs/to.gif'
import welcome from '../gifs/welcome.gif'
import { urlencoded } from 'body-parser';



 const Welcome = ({gifActive}) => {

    const [gif, setGif] = useState('')
    const [blur, setBlur] = useState(false)

    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])

    const handleMouseEnter = (e, x) => {
        console.log("Mouse In")
        setGif(x);
        setBlur(true)
        e.target.style.opacity = 1
    }

    const handleMouseLeave = (e) => {
        setGif('');
        setBlur(false);
        e.target.style.opacity = ''
    }
   

    return (
        <WelcomeBox style={{ overflow:'hidden'}} id="home-banner" src={gif}>
                <div style={{position:'relative', width:'100%', height:'55vh',marginTop:'10vh'}} data-aos="fade-up" > 
                    {/* <span>
                            <span onMouseEnter={(e) => handleMouseEnter(e, welcome)} onMouseLeave={handleMouseLeave}>
                                <SoundWord word='WELCOME' sound='/media/sounds/welcome.mp3' blur={blur} />
                            </span>
                            <span onMouseEnter={(e) => handleMouseEnter(e, all)} onMouseLeave={handleMouseLeave}>
                                <SoundWord word=' ALL' sound='/media/sounds/all.mp3' blur={blur}  />
                            </span>
                            <span onMouseEnter={(e) => handleMouseEnter(e, to)} onMouseLeave={handleMouseLeave}>
                                <SoundWord word=' TO' sound='/media/sounds/to.mp3' blur={blur}  />
                            </span>
                    </span>
                    <br /> */}
                    <span>
                        {/* <span onMouseEnter={(e) => handleMouseEnter(e, to)} onMouseLeave={handleMouseLeave}>
                            <SoundWord word=' TO' sound='/media/sounds/to.mpeg' blur={blur}  />
                        </span> */}
                        <span onMouseEnter={(e) => handleMouseEnter(e, makamaka)} onMouseLeave={handleMouseLeave}>
                            <SoundWord word='MAKAMAKA' sound='/media/sounds/sexy_thing.mp3' blur={blur}  />
                        </span>
                        <span onMouseEnter={(e) => handleMouseEnter(e, beach)} onMouseLeave={handleMouseLeave}>
                            <SoundWord word=' BEACH ' sound='/media/sounds/beach.mp3' blur={blur} />
                        </span>
                    </span> 
                    <br />
                    <span>
                        {/* <span onMouseEnter={(e) => handleMouseEnter(e, beach)} onMouseLeave={handleMouseLeave}>
                            <SoundWord word='BEACH ' sound='/media/sounds/kokomo.mp3' blur={blur} />
                        </span> */}
                        <span onMouseEnter={(e) => handleMouseEnter(e, burger)} onMouseLeave={handleMouseLeave}>
                            <SoundWord word='BURGER' sound='/media/sounds/burger.mpeg' blur={blur} />
                        </span>
                        <span onMouseEnter={(e) => handleMouseEnter(e, cafe)} onMouseLeave={handleMouseLeave}>
                            <SoundWord word=' CAFE' sound='/media/sounds/good_vibrations.mp3' blur={blur} />
                        </span>
                    </span>
                    
                </div>    

                
                <TextMove style={{ zIndex:'10', backgroundPosition:"center 9px"}}>
                    <Marquee gradient={false} speed={100} >
                        <span style={{textTransform:'uppercase', fontSize:'0.18rem', lineHeight:'0.5rem', fontWeight:'400', fontFamily:'ITC_Bold'}}>
                            Estamos Abiertos! We are Open! Estamos Abiertos! We are Open! Estamos Abiertos! We are Open! Estamos Abiertos! We are Open! Estamos Abiertos! We are Open!&nbsp; 
                        </span>
                    </Marquee>
                </TextMove> 
                
     
       
            
        </WelcomeBox>
    )
}

export default Welcome

  {/* <SoundSticker sound='/media/sounds/all.mp3' img={<Stickers src={window.location.origin + '/media/stickers/all.png'} className='hand-black' height='25%' width='auto'/>}/> */}

     {/* <div style={{position:'absolute', top:Y, left:X }}>
                <span><img src={window.location.origin + '/media/stickers/cursor_terazza.png'} height='100%' width='auto'/></span>
            </div> */}