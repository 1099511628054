import React from 'react'
import { FooterText, FooterTitle, FTBox} from '../styles/Text'
import { FooterBox } from '../styles/Containers'
import button_group from '../../images/button_group.png'
import button_reservas from '../../images/button_reservas.png'

const Footer = () => {

    const clickMenu = () => {
        window.open("/media/mm_menu.pdf","_blank");
    }

    return (
        <FooterBox style={{height:'110vh', flexDirection:'column', gap:'5vh', textAlign:'center', justifyContent:'center', paddingTop:'5vh', paddingBottom:'5vh'}}>
            <div style={{margin:'auto 0', textAlign:"center"}}>
                <img src={window.location.origin + '/media/logo_movil.png'} style={{height:"20vh"}}/>
            </div> 

            <div style={{display:'flex', flexDirection:'column', gap:'2vh', margin:'auto 0'}}>
                <FooterTitle>
                    Where we are?
                </FooterTitle>
                <FooterText style={{fontWeight:'bold'}}>
                    Passeig Joan de Borbó 76,<br />
                    08039 Barcelona
                </FooterText>
                <FooterText>
                    <b>Lu-Ju:</b> 1pm - 12:30am<br />
                    <b>Vi-Dom:</b> 12pm - 12:30am
                </FooterText>
            </div> 
            <div style={{position:'relative'}}>
                    <img src={button_group} className="hand-black" height='50vh' width='auto' onClick={clickMenu} />
                    <a href="https://www.google.com/maps/reserve/v/dine/c/eFY5BCNmbLU?source=pa&hl=es-ES&gei=kaQuZLChHsGQkdUP19iL0AQ&sourceurl=https://www.google.com/search?q%3Dmakamaka%2Bbarcelona%26sxsrf%3DAPwXEdcl4IavFg94LRWbhRMEnqaOQQBkWQ:1680778340598%26ei%3DZKQuZLqKJLOmkdUPpOWH2Ag%26ved%3D0ahUKEwi6zL-fi5X-AhUzU6QEHaTyAYsQ4dUDCA8%26uact%3D5%26oq%3Dmakamaka%2Bbarcelona%26gs_lcp%3DCgxnd3Mtd2l6LXNlcnAQAzIQCC4QDRCABBDHARCvARDqBDIICAAQgAQQywEyBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjICCCY6CggAEEcQ1gQQsAM6BAgjECc6BggjECcQEzoLCC4QrwEQxwEQgAQ6BQgAEIAEOg4ILhCABBDHARCvARDqBDoLCC4QgAQQxwEQrwFKBAhBGABQ_gdYzRVgrhdoAXAAeACAAaEBiAHVB5IBAzMuNpgBAKABAcgBA8ABAQ%26sclient%3Dgws-wiz-serp" >
                        <img src={button_reservas} className="hand-black" height='53vh' width='auto' />
                    </a>
            </div>

            <div style={{display:'flex', flexDirection:'column', gap:'2vh', margin:'auto 0'}}>
                <FooterTitle>
                    Follow
                </FooterTitle>
                <FooterText>
                    <FTBox>
                    <img src={window.location.origin + '/media/general/facebook.png'} height='25vh' width='auto' />
                        <a href='https://www.facebook.com/MakamakaBcn?ref=hl'>
                            <span>&nbsp;Facebook</span>
                        </a>
                    </FTBox>
            
                    <FTBox>
                    <img src={window.location.origin + '/media/general/instagram.png'} height='25vh' width='auto' />
                        <a href='https://www.instagram.com/makamakabcn/'>
                            <span>&nbsp;Instagram</span>
                        </a>
                    </FTBox>
          
                    <FTBox>
                    <img src={window.location.origin + '/media/general/pinterest.png'} height='25vh' width='auto' style={{marginLeft:'-2vh'}}/>
                        <a href='https://www.pinterest.es/makamakabarcelona/'>
                            <span>&nbsp;Pinterest</span>
                        </a>
                    </FTBox>
             
                    <FTBox>
                    <img src={window.location.origin + '/media/general/tik_tok.png'} height='23vh' width='auto' style={{marginLeft:'-3vh'}}/>
                        <a href='https://www.tiktok.com/@makamakabcn'>
                            <span>&nbsp;Tik Tok</span>
                        </a>
                    </FTBox>
                </FooterText>

            </div> 

            <div style={{display:'flex', flexDirection:'column', gap:'2vh', margin:'auto 0'}}>
                <FooterTitle>
                    Contact
                </FooterTitle>
                <FooterText>
                    <FTBox> 
                    <img src={window.location.origin + '/media/general/mail.png'} height='22vh' width='auto' style={{verticalAlign:'middle'}}/>
                        <a href='mailto:hola@makamaka.es'>
                            <span>&nbsp;hola@makamaka.es</span>
                        </a>
                    </FTBox>
                    <FTBox>
                            <img src={window.location.origin + '/media/general/phone.png'} height='22vh' width='auto' style={{verticalAlign:'middle', marginLeft:'-5vh'}}/>
                            <a href='tel:+34932213520'>
                                &nbsp;<span>+34 932 213 520</span>
                            </a>    
                    </FTBox>
                </FooterText>
            </div> 
            
        </FooterBox>
    )
}

export default Footer
