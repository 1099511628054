import {useEffect} from 'react'
import { FamilyBox, PhotoGrid, FamilyPhotos, Spacer } from './styles/Containers'
import { Stickers, MenuStickers } from './styles/Containers'
import { WelcomeText, TextS } from './styles/Text'
import { Widget } from '@typeform/embed-react'
import FamilyForm from './FamilyForm'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Family = () => {


    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])

    return (
        <FamilyBox id='family'>   
                {/* <WelcomeText style={{fontSize:'10vh'}}id="family">Meet Our Family!</WelcomeText> */}
                {/* <TextS style={{fontFamily:'Garamond', padding:'0 20vw'}}>Una <b>gran familia</b> conlleva una <b>gran responsabilidad</b>, por eso nos preocupamos de montar un <b>buen show</b> para tenerlos <b>contentos</b> cada día.</TextS>
                <Spacer space='10vh'/> */}
                    <FamilyPhotos data-aos="fade-up">
                        <div style={{}}>
                            <PhotoGrid>
                                <img src={window.location.origin + '/media/family/1.png'} style={{border:'1vh solid white',transform:'rotate(-0.78deg)'}}/>
                                <img src={window.location.origin + '/media/family/2.png'} style={{border:'1vh solid white',transform:'rotate(1.18deg)'}}/>
                                <img src={window.location.origin + '/media/family/5.png'} style={{border:'1vh solid white', transform:'rotate(1.98deg)'}}/>
                            </PhotoGrid>
                            <Spacer space='2vw'/>
                            <PhotoGrid>
                                <img src={window.location.origin + '/media/family/4.png'} height='100%' width='auto' style={{border:'1vh solid white', transform:'rotate(2.18deg)'}}/>
                                <img src={window.location.origin + '/media/family/3.png'} height='100%' width='auto' style={{border:'1vh solid white', transform:'rotate(0.78deg)'}}/>
                                <img src={window.location.origin + '/media/family/6.png'} height='100%' width='auto' style={{border:'1vh solid white', transform:'rotate(-3.78deg)'}}/>
                            </PhotoGrid>
                        </div>
       
                    </FamilyPhotos>
                    <Spacer space='8vh'/>
                    <TextS style={{fontFamily:'Garamond', padding:'0 20vw'}}>Una <b>gran familia</b> conlleva una <b>gran responsabilidad</b>, por eso nos preocupamos de montar un <b>buen show</b> para tenerlos <b>contentos</b> cada día.</TextS>
                {/* <NavLink to='/friends'><Stickers src={window.location.origin + '/media/stickers/friends.png'} className='animate-friend' height='10%' width='auto' style={{position:'absolute', top:'20vh', right:'20vw'}}/></NavLink> */}
                {/* <NavLink to='/friends'>
                    <MenuStickers src={window.location.origin + '/media/stickers/friends.png'} className='animate-friend' width='30%' height='auto'  style={{position:'absolute', top:'10%', right:'15%'}}/></NavLink> */}
                {<Spacer space='15vh' />}
                {/* <div style={{height:'80vh', width:'100vw'}}>
                    <Widget id="wCdm3C6E" style={{ width:'100%', height:'100%' }} className="my-form" />
                </div> */}
                {/* <FamilyForm /> */}
        </FamilyBox>
    )
}

export default Family


                 {/* <div style={{height:'80vh'}}>
                            <img src={window.location.origin + '/media/family/family_5.png'} height='110%' width='auto' />
                        </div> */}
