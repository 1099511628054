import React, { useEffect, useState } from 'react';
import './loading.css';
import logo from "../../../assets/img/makamaka.png";

export function Loading() {
	const [fadeOut, setFadeOut] = useState(false);
	const [showLoading, setShowLoading] = useState(true);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setFadeOut(true);
		}, 800);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	useEffect(() => {
		if (fadeOut) {
			const fadeOutDuration = 600;
			const hideLoadingTimeout = setTimeout(() => {
				setShowLoading(false);
			}, fadeOutDuration);

			return () => {
				clearTimeout(hideLoadingTimeout);
			};
		}
	}, [fadeOut]);

	if (!showLoading) {
		return null;
	}

	return (
		<div className={`gau-preloader-wrapper ${fadeOut ? 'gau-fade-out' : ''}`}>
			<div className="gau-preloader">
				<div id="gau-loading">
					<img style={{ width: '200px' }} src={logo} alt="logo makamaka" />
				</div>
			</div>
		</div>
	);
}
