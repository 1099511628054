import { PlaylistBanner, Cassette } from "./styles/Containers"
import UseMouse from "./UseMouse"
import Marquee from "react-fast-marquee"
import SoundCassette from "./SoundCassette"


const Playlist = () => {

    const { x, y} = UseMouse()

    const Y = y - '100' 

    const X = x - '150'

    return (
        <div style={{position:'relative', overflow:'hidden'}}>
        <PlaylistBanner >       
            <Marquee gradient={false} speed={300} pauseOnHover={true} pauseOnClick={true}>   
                <SoundCassette src='/media/songs/cassette_5.png' height='51%' sound='/media/sounds/california_corto.mp3' />
                <SoundCassette src='/media/songs/cassette_2.png' height='65%' sound='/media/sounds/daddy_daddy_cool_corto.mp3' />
                <SoundCassette src='/media/songs/cassette_3.png' height='62%' sound='/media/sounds/blue_suede_corto.mp3' />
                <SoundCassette src='/media/songs/cassette_4.png' height='51%' sound='/media/sounds/surfin_usa.mp3' />  
            </Marquee>          
        </PlaylistBanner>
        <a href='https://open.spotify.com/playlist/2etSG6ZZ6zaDslCXuFaY1L?si=NDTRne5RRDiFDYd1I6rFXg'><img src={window.location.origin + '/media/stickers/playlist_tag.png'} className='animate-live hand-black' height='30%' width='auto' style={{position:'absolute', zIndex:'2', bottom:'2vh', right:'15%'}}/></a>
            {/* <div style={{position:'absolute', top:Y, left:X, zIndex:'100'}}>
                <span style={{pointerEvents:'none'}}><img src={window.location.origin + '/media/stickers/songs_cursor.png'} height='20%' width='auto'/></span>
            </div> */}
        </div>
    )
}

export default Playlist
