import { useState } from "react"
import ModalShop from "./ModalShop"
import { GridBox, GridLine, MenuImg } from "./styles/Containers";


const RefrescosGrid = () => {

    const [show, setShow] = useState(false)
    const [img, setImg] = useState('')
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')

    const Refrescos = [
        {
            name: "Bobby's Ice Tea",
            tagline: 'Bobby says refresh',
            img:'/media/refrescos/bobbys_ice_tea.png',
            angle:'rotate(2.23deg)'
        },
        {
            name: "Jackie's Ice Tea",
            tagline: 'Refreshingly Different',
            img:'/media/refrescos/jackies_ice_tea.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: "Lola's Lemonade",
            tagline: 'Fresh homemade lemonade',
            img:'/media/refrescos/lolas_lemonade.png',
            angle:'rotate(0.23deg)'
        },
        {
            name: "Stevies Wonder Lemonade",
            tagline: 'The healthiest Choice',
            img:'/media/refrescos/stevies_wonder_lemonade.png',
            angle:'rotate(-2.23deg)'
        },
        {
            name: 'Banana Republic',
            tagline: 'Peace, love & banana',
            img:'/media/refrescos/banana_republic.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Fan of Tan',
            tagline: 'Stay Golden',
            img:'/media/refrescos/fan_of_tan.png',
            angle:'rotate(-3.23deg)'
        },
        {
            name: 'Gold Digger',
            tagline: 'Make it rain',
            img:'/media/refrescos/gold_digger.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Shark Bite',
            tagline: 'No pain, no gain',
            img:'/media/refrescos/shark_bite.png',
            angle:'rotate(1.123deg)'
        }
    ]


    const enlarge = async (food, e) => {

        


        setName(food.name)
        setTag(food.tagline)
        setImg(food.img)
        setShow(true)
        console.log(e.style)
        
    }

    return (
        <GridBox>

                <GridLine>
                <MenuImg src={window.location.origin + Refrescos[0].img}  alt="1" style={{transform:'rotate(2.23deg)'}} onClick={(target) => enlarge(Refrescos[0], target)} /> 
                <MenuImg src={window.location.origin + Refrescos[1].img} style={{transform:'rotate(0.9deg)'}} onClick={() => enlarge(Refrescos[1])}/> 
                <MenuImg src={window.location.origin + Refrescos[2].img} style={{transform:'rotate(-1.2deg)'}} onClick={() => enlarge(Refrescos[2])}/> 
                </GridLine>
                

                <GridLine>
                <MenuImg src={window.location.origin + Refrescos[3].img} style={{transform:'rotate(3.23deg)'}} onClick={() => enlarge(Refrescos[3])}/> 
                <MenuImg src={window.location.origin + Refrescos[4].img} style={{transform:'rotate(-1.7deg)'}} onClick={() => enlarge(Refrescos[4])} /> 
                <MenuImg src={window.location.origin + Refrescos[5].img} style={{transform:'rotate(-0.5deg)'}} onClick={() => enlarge(Refrescos[5])}/> 
                </GridLine>
            


                <GridLine>
                <MenuImg src={window.location.origin + Refrescos[6].img}  style={{transform:'rotate(1.76deg)'}} onClick={() => enlarge(Refrescos[6])} /> 
                <MenuImg src={window.location.origin + Refrescos[7].img} style={{transform:'rotate(0.34deg)'}} onClick={() => enlarge(Refrescos[7])} /> 
                </GridLine>

                {show && <ModalShop img={img} show={show} setShow={setShow} name={name} tag={tag} />}
  
        </GridBox>
    )
}

export default RefrescosGrid
