   
import React, { useState, useMemo, useRef } from 'react'
import TinderCard from 'react-tinder-card'
import { MTinderGame, GifBox } from '../styles/Containers'
import { GifTextM, TinderInfoM, GifBlink } from '../styles/Text'
import { useNavigate } from 'react-router'
import congrats from '../../gifs/tinder_congrats.gif'
import { transform } from 'framer-motion'



// Put the buttons as the other tinder example
// 

const Advanced = () => {

  const db = [
    {
      name: 'Arnold',
      url: './media/tinder/cards/Arnold.png',
      msgY:'10 points!',
      msgN:'Go for a walk and try again later',
      gifY:'./media/tinder/gifs/mobile/makaverse.gif',
      gifN:'./media/tinder/gifs/mobile/go_for_a_walk.gif',
      count: 1,
      turn: '-3.2deg'
    },
    {
      name: 'David',
      url: './media/tinder/cards/David.png',
      msgY:'Yaaaaaaaas!',
      msgN:'Emotional damage...',
      gifY:'./media/tinder/gifs/mobile/makaverse.gif',
      gifN:'./media/tinder/gifs/mobile/emotional_damage.gif',
      count: 1,
      turn: '-2.22deg'
    },
    {
      name: 'Pamela',
      url: './media/tinder/cards/Pamela.png',
      msgY:'Wise Choice <3',
      msgN:'How dare you?!',
      gifY:'./media/tinder/gifs/mobile/wise_choice.gif',
      gifN:'./media/tinder/gifs/mobile/how_dare_you.gif',
      count: 1,
      turn: '-1.2deg'
    },
    {
      name: 'Police',
      url: './media/tinder/cards/Police.png',
      msgY:'Not even mad... just disappointed!',
      msgN:'We are close to match!',
      gifY:'./media/tinder/gifs/mobile/not_even_mad.gif',
      gifN:'./media/tinder/gifs/mobile/close_to_match.gif',
      count: 0,
      turn: '1.82deg'
    },
    {
      name: 'Ivan',
      url: './media/tinder/cards/Ivan.png',
      msgY:'How dare you!',
      msgN:'Yaaaaaaaas!',
      gifY:'./media/tinder/gifs/mobile/how_dare_you_2.gif',
      gifN:'./media/tinder/gifs/mobile/yaaas.gif',
      count: 0,
      turn: '1.2deg'
    },
    {
      name: 'Hartman',
      url: './media/tinder/cards/Hartman.png',
      msgY:'Ok! I am out of here!',
      msgN:'We are close to match!',
      gifY:'./media/tinder/gifs/mobile/ok_Iam_out_of_here.gif',
      gifN:'./media/tinder/gifs/mobile/close_to_match_2.gif',
      count: 0,
      turn:'-2.89deg'
    },
    {
      name: 'MakaMaka',
      url: './media/tinder/cards/MakaMaka.png',
      msgY:'One step closer!',
      msgN:'Are you trying to be a maka enemy?',
      gifY:'./media/tinder/gifs/mobile/one_step_closer.gif',
      gifN:'./media/tinder/gifs/mobile/maka_enemy.gif',
      count: 1,
      turn: '3.4deg'
    },
  ]
  
  const navigate = useNavigate()

    const [currentIndex, setCurrentIndex] = useState(db.length - 1)
    const [lastDirection, setLastDirection] = useState('')
    const [msg, setMsg] = useState('')
    const [imgSrc, setImgSrc] = useState('')
    const [show, setShow] = useState(false)
    const [nope, setNope] = useState(false)
    const [like, setLike] = useState(false)
    const [loser, setLoser] = useState(false)
    const [winner, setWinner] = useState(false)
    const [total, setTotal] = useState(0)

    const score = useRef(0);
    
    // used for outOfFrame closure
    const currentIndexRef = useRef(currentIndex)
    const childRefs = useMemo(
        () =>
          Array(db.length)
            .fill(0)
            .map((i) => React.createRef()),
        []
      )
    
    const swipe = async (dir) => {
      
        if (canSwipe && currentIndex < db.length) {
          if(dir === 'left'){
            setNope(true)
          }
          if(dir === 'right'){
            setLike(true)
          }
          await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
        }
    }

    // levels of 5, 8, 10
    const TinderResult = (x) => {
      if ( x < 1){
        setLoser(true)
      } else {
        setWinner(true)
      }
    }

    const outOfFrame = (name, idx) => {
        currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    }

    const updateCurrentIndex = (val) => {
        setCurrentIndex(val)
        currentIndexRef.current = val
    }
    
    
    const canSwipe = currentIndex >= 0
    
    const swiped = (direction, nameToDelete, index, msgN, msgY, gifN, gifY, count) => {
        setLastDirection(direction)

        if(count == 0 && direction == 'left'){
          console.log ("Should increase by 1")
         setTotal(total + 1)
         score.current = score.current + 1
        } else if(count == 1 && direction == 'right') {
          console.log ("Should increase by 1")
          setTotal(total + 1)
          score.current =  score.current + 1
        } else {
          console.log ("Should minus by 1")
          setTotal(total - 1)
          score.current = score.current - 1
        } 

       console.log("Total is " + total)
       console.log("Score is " + score.current)
       
       if(index === 0)
       {
         TinderResult(score.current)
       } else {

         if(direction ==='right'){
           setMsg(msgY)
           setImgSrc(gifY)
       } else {
           setMsg(msgN)
           setImgSrc(gifN)
       }
     
       setTimeout(() => {
           setLike(false)
           setNope(false)
       }, 300 )
       setTimeout(() => {
           updateCurrentIndex(index - 1)
           setShow(true)
       }, 500 )

       }
       

       
    }
    
   

    return (
    <MTinderGame>
        <link href='https://fonts.googleapis.com/css?family=Damion&display=swap' rel='stylesheet' />

        <link href='https://fonts.googleapis.com/css?family=Alatsi&display=swap' rel='stylesheet' />

        {/* <h1>Be a Maka Friend</h1> */}

        <div className='cardContainerM'>
          
        {db.map((character, index) => 
            (  <TinderCard
                  ref={childRefs[index]}
                  className='swipe'
                  key={character.name}
                  onSwipe={(dir) => swiped(dir, character.name, index, character.msgN, character.msgY, character.gifN, character.gifY, character.count )}
                  onCardLeftScreen={() => outOfFrame(character.name, index)}
                >
                  <div
                    style={{ backgroundImage: 'url(' + character.url + ')', transform: 'rotate(' + character.turn + ')'}}
                    className='cardM'
                  >
                    {/* <h3>{character.name}</h3> */}
                  {  currentIndex === index &&
                      <div style={{position:'absolute', zIndex:'2', paddingTop:'10vh',width:'20vw'}}>
                        { like &&  <img src='./media/tinder/overlays/like.png' width='100%' height='auto' />}
                        { nope &&  <img src='./media/tinder/overlays/nope.png' width='100%' height='auto' />}
                      </div>
                    }
                  </div>
                </TinderCard> )
        )}
      </div>
        
        <div className='Mbuttons'>
            <button onClick={() => swipe('left')}><img src='./media/tinder/buttons/Nope.png' height='100%'/></button>
            {/* <button style={{ backgroundColor: !canGoBack !canSwipe && '#c3c4d3' }} onClick={() => goBack()}>Undo swipe!</button> */}
            <button onClick={() => swipe('right')}><img src='./media/tinder/buttons/Like.png' height='100%'/></button>
        </div>

        {lastDirection ? null : (
            <div style={{width:'80%', textAlign:'center', marginTop:'-5vh'}}>
              <TinderInfoM style={{lineHeight:'1.1'}}>
                We need to see if we can be a good match!
              </TinderInfoM>
            </div>
        )}

        {show && <GifBox gif={imgSrc} onClick={() => setShow(false)}>
            <GifTextM>
              <span>{msg}</span>
            </GifTextM>
            <GifBlink>
              <span style={{fontSize:'3vh'}}>Click anywhere to continue</span>
            </GifBlink>
          {/* <img src={imgSrc} alt='Did not work' heigh='400%' width='auto' /> */}
        </GifBox>}

        {loser && <GifBox gif={window.location.origin + '/media/tinder/gifs/mobile/go_for_a_walk.gif'} style={{position:'fixed', zIndex:'2',height:'100vh', width:'100vw'}} onClick={() => {navigate("/")}}>
            <GifTextM>
              <span>Go for a walk and try again later!</span>
            </GifTextM>
            <GifBlink>
              <span>Click anywhere to continue</span>
            </GifBlink>
          {/* <img src={window.location.origin + '/media/tinder/gifs/mobile/go_for_a_walk.gif'} alt='Did not work' heigh='100%' width='100%' /> */}
        </GifBox>}

        {winner && <GifBox gif={congrats} onClick={() => {navigate(("/makafriend"))}}>
            <GifTextM>
              <span>YOU WON THE TINDER GAME</span>
            </GifTextM>
            <GifBlink>
              <span>Click anywhere to continue</span>
            </GifBlink>
          {/* <img src={window.location.origin + '/media/tinder/gifs/congrats.gif'} alt='Did not work' heigh='100%' width='100%' /> */}
        </GifBox>}

    </MTinderGame>
    )
}

export default Advanced
