import { useState } from "react"
import { MenuImgM } from "../styles/Containers"

const BurgerGrid = () => {

    const [show, setShow] = useState(false)
    const [img, setImg] = useState('')
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')

    const Burgers = [
        {
            name: 'BAD ASS',
            tagline: "OU`ANGUS YOUNG' BURGER",
            img:'/media/burgers/bad_ass.png',
            angle:'rotate(2.23deg)'
        },
        {
            name: 'Bean Machine!',
            tagline: 'Do me a solid',
            img:'/media/burgers/bean_machine.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'The Game Changer',
            tagline: 'Dig In, Man',
            img:'/media/burgers/the_game_changer.png',
            angle:'rotate(0.23deg)'
        },
        {
            name: 'Naked',
            tagline: 'Nothing but the naked true!',
            img:'/media/burgers/naked.png',
            angle:'rotate(-2.23deg)'
        },
        {
            name: 'Hot Mama 2.0',
            tagline: 'Cheesier Than Ever',
            img:'/media/burgers/hot_mama.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'The Dude',
            tagline: 'Dig In, Man',
            img:'/media/burgers/the_dude.png',
            angle:'rotate(-3.23deg)'
        },
        {
            name: 'The Nice Guy',
            tagline: "He's a keeper(and a veggie too)",
            img:'/media/burgers/the_nice_guy.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Maradona',
            tagline: 'The Legend is back',
            img:'/media/burgers/maradona.png',
            angle:'rotate(1.123deg)'
        },
        {
            name: 'Big Maka',
            tagline: 'The Ultimate Beach Burger',
            img:'/media/burgers/big_maka.png',
            angle:'rotate(1.56deg)'
        }

    ]


    const enlarge = async (food, e) => {

        


        setName(food.name)
        setTag(food.tagline)
        setImg(food.img)
        setShow(true)
        console.log(e.style)
        
    }

    return (
        <div style={{width:'100vw', padding:'0 5vw', position:'relative' }}>


                
                <MenuImgM src={window.location.origin + Burgers[0].img}  alt="1" style={{transform:'rotate(2.23deg)'}} onClick={(target) => enlarge(Burgers[0], target)} /> 
                <MenuImgM src={window.location.origin + Burgers[1].img} style={{transform:'rotate(0.9deg)'}} onClick={() => enlarge(Burgers[1])}/> 
                <MenuImgM src={window.location.origin + Burgers[2].img} style={{transform:'rotate(-1.2deg)'}} onClick={() => enlarge(Burgers[2])}/> 
             
                

              
                <MenuImgM src={window.location.origin + Burgers[3].img} style={{transform:'rotate(3.23deg)'}} onClick={() => enlarge(Burgers[3])}/> 
                <MenuImgM src={window.location.origin + Burgers[4].img} style={{transform:'rotate(-1.7deg)'}} onClick={() => enlarge(Burgers[4])} /> 
                <MenuImgM src={window.location.origin + Burgers[5].img} style={{transform:'rotate(-0.5deg)'}} onClick={() => enlarge(Burgers[5])}/> 
               
            


               
                <MenuImgM src={window.location.origin + Burgers[6].img}  style={{transform:'rotate(1.76deg)'}} onClick={() => enlarge(Burgers[6])} /> 
                <MenuImgM src={window.location.origin + Burgers[7].img} style={{transform:'rotate(0.34deg)'}} onClick={() => enlarge(Burgers[7])} /> 
                <MenuImgM src={window.location.origin + Burgers[8].img} style={{transform:'rotate(-3.12.0deg)'}} onClick={() => enlarge(Burgers[8])}/>
               

                {/* {show && <div style={{backgroundColor:'rgba(0,0,0,0.1)', height:'100vh', width:'100vw', position:'fixed', zIndex:'2', top:'0%', left:'0%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}} onClick={() => setShow(false)}>

                            <EnlargedBox src={img}>
                            <span style={{position:'absolute',  top:'2vh', right:'1vw', fontSize:'5vh', fontWeight:'bold', cursor:'pointer', color:'white'}} onClick={()=> setShow(!show)}>X</span>
                                <div>
                                    <FBText>{name}</FBText>
                                    <br/>
                                    <Spacer space='2vh'/>
                                    <FBTag>{tag}</FBTag>                             
                                </div>
                            </EnlargedBox>
              
                    
                </div>} */}
  
        </div>
    )
}

export default BurgerGrid
