import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { MenuBox, TextMove } from '../../components/styles/Containers'
import { WelcomeText, TextS } from '../../components/styles/Text'
import { BtnFood } from '../../components/styles/Forms'
import { Spacer } from '../../components/styles/Containers'
import BurgerGrid from '../../components/BurgerGrid'
import SaladGrid from '../../components/SaladGrid'
import MainMenuHome from '../../components/MainMenuHome'
import Footer from '../../components/Footer'
import AnimatedPages from '../../components/AnimatedPages'
import Marquee from 'react-fast-marquee'

const Hungry = () => {

    const { food } = useParams();
    const [ burger, setBurger] = useState(true)
    const [ salad, setSalad] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)

        if(food === 'salads'){
            setSalad(true)
            setBurger(false)
        } else {
            setBurger(true)
            setSalad(false)
        }

    })
    
    return (
        <AnimatedPages>
            <MainMenuHome />
            <MenuBox style={{overflow:'scroll', paddingBottom:'10vh', margin:0, paddingLeft:0, paddingRight:0, width:"100vw", overflow:'hidden'}}>
                <WelcomeText style={{lineHeight:'1.1'}}>Best Burgers in Town!</WelcomeText>
                <Spacer space='2vh' />
                <TextS>Creamos <b>burgers</b> y comida perfecta para un día<br/> de surf en <b>La Barceloneta</b> desde 2012 </TextS>
                <Spacer space='5vh' />
                <div style={{display:'flex', gap:'5vw'}}>
                    <Link to='/hungry/burgers'><BtnFood>Burgers</BtnFood></Link>
                    <Link to='/hungry/salads'><BtnFood>Salads & Co</BtnFood></Link>
                </div>
                <Spacer space='5vh'/>
                {burger && <BurgerGrid />}
                {salad && <SaladGrid />}
                <Spacer space='10vh'/>
                <TextS>Toca Bajar esta comida con algo fresquito...</TextS>
                <Spacer space='2vh'/>
                <div style={{display:'flex', gap:'5vw'}}>
                        <Link to='/thirsty/cocktails'><BtnFood>Cocktails</BtnFood></Link>
                        <Link  to='/thirsty/refrescos'><BtnFood>Refrescos</BtnFood></Link>
                </div>
                <TextMove style={{bottom:'0', position:"absolute", bottom:"-10px", zIndex:'0'}}>
                    <Marquee gradient={false} speed={100} direction="right" >
                        <span style={{fontSize:'0.2rem', lineHeight:'0.35rem', fontFamily:'Baskerville'}}>
                        ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi?&nbsp;
                        </span>
                    </Marquee>
                </TextMove>
            </MenuBox>
            <Footer />
        </AnimatedPages>
    )
}

export default Hungry
