import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { MenuBox, TextMove } from '../../components/styles/Containers'
import { WelcomeText, TextS } from '../../components/styles/Text'
import { BtnFood } from '../../components/styles/Forms'
import { Spacer } from '../../components/styles/Containers'
import CocktailsGrid from '../../components/CocktailsGrid'
import RefrescosGrid from '../../components/RefrescosGrid'
import MainMenuHome from '../../components/MainMenuHome'
import Footer from '../../components/Footer'
import AnimatedPages from '../../components/AnimatedPages'
import Marquee from 'react-fast-marquee'

const Thirsty = () => {

    const { drinks } = useParams();
    const [ cocktails, setCocktails] = useState(true)
    const [ refrescos, setRefrescos] = useState(false)

    useEffect(() => {

        window.scrollTo(0, 0)

        if(drinks === 'refrescos'){
            setRefrescos(true)
            setCocktails(false)
        } else {
            setCocktails(true)
            setRefrescos(false)
        }

    })
    
    return (
        <AnimatedPages>
            <MainMenuHome />
            <MenuBox style={{paddingLeft:0, marginLeft:0}}>
                <WelcomeText style={{lineHeight:'1.1'}}>Beware of Drinks!</WelcomeText>
                <Spacer space='2vh' />
                <TextS>Creamos <b>Cocktails</b> y refresco perfectos para un día<br/> de surf en <b>La Barceloneta</b> desde 2012 </TextS>
                <Spacer space='5vh' />
                <div style={{display:'flex', gap:'5vw'}}>
                    <Link to='/thirsty/cocktails'><BtnFood>Cocktails</BtnFood></Link>
                    <Link to='/thirsty/refrescos'><BtnFood>Refrescos</BtnFood></Link>
                </div>
                <Spacer space='5vh'/>
                {cocktails && <CocktailsGrid />}
                {refrescos && <RefrescosGrid />}
                <Spacer space='10vh'/>
                <TextS>Toca Bajar esta comida con algo fresquito...</TextS>
                <Spacer space='2vh'/>
                <div style={{display:'flex', gap:'5vw'}}>
                    <Link to='/hungry/burgers'><BtnFood>Burgers</BtnFood></Link>
                    <Link to='/hungry/salads'><BtnFood>Salads & Co</BtnFood></Link>
                </div>

                <TextMove style={{bottom:'0', position:'absolute', bottom:"-10px", zIndex:'0'}}>
                    <Marquee gradient={false} speed={100} direction="right" >
                        <span style={{fontSize:'0.2rem', lineHeight:'0.3rem', fontFamily:'Baskerville'}}>
                        ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi?&nbsp;
                        </span>
                    </Marquee>
                </TextMove>
            </MenuBox>       
            <Footer />
        </AnimatedPages>
    )
}

export default Thirsty



