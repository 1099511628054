import React from 'react'
import { FBMainDiv } from '../styles/Containers'
import { TextS } from '../styles/Text'
import { Spacer } from '../styles/Containers'
import { BtnFoodM } from '../styles/Forms'
import fb_bw from '../../images/welcome.jpg'
import { NavLink } from 'react-router-dom'


const Intro = ({onClick}) => {


    return (
        <FBMainDiv src={fb_bw} style={{position:'fixed', zIndex:'100'}}>
            <div style={{position:'relative', height:'100%', width:'100%', background:'rgba(0,0,0,0.8)',display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <img src={window.location.origin + '/media/logo_white.png'} className="hand-black" height='5%' width='auto' style={{ position:'absolute', top:'5vh'}}/>
                <Spacer space='12vh'/>
                <div style={{width:'80%',textAlign:'center', marginTop:'0vh'}}>
                        <TextS style={{color:'#F9F3E5', fontSize:'4vh', textAlign:'center', fontFamily:'Garamond', fontWeight:'500'}}>¿Quieres vivir la auténtica experiencia Makamaka?
                        <Spacer space='8vh'/>
                        Apaga el ordenador y ven al restaurante! <br />
                        En los 70' no tenias ni ordenador, ni móvil... </TextS>
                </div>
                <Spacer space='10vh'/>
                <div style={{display:'flex', gap:'3vw'}}>
                    <BtnFoodM><a href='https://www.google.com/maps/place/Makamaka+Beach+Burger+Cafe/@41.3757503,2.1864254,17z/data=!3m1!4b1!4m5!3m4!1s0x12a4a3abf8a3165d:0x68481558343847f7!8m2!3d41.3757503!4d2.1886141'>Take Me There</a></BtnFoodM>
                    <BtnFoodM onClick={onClick}>Visit Website</BtnFoodM>
                </div>
            </div>

        </FBMainDiv>
    )
}

export default Intro
