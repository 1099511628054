import { useEffect } from 'react'
import { TextMove, TextMoveM, TextMoveWhite } from '../styles/Containers'
import Marquee from "react-fast-marquee"
import Aos from 'aos'
import 'aos/dist/aos.css'
import map from '../../images/map_mobile.jpg'

const LocationMap = () => {

    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])


    return (
        <div style={{height:'100vh', position:'relative', zIndex:'0', overflow:'hidden'}} id='where'>
            <TextMove style={{top:'0', position:'absolute', top:"-10px"}}>
                <Marquee gradient={false} speed={100} >
                    <span style={{fontSize:'0.2rem', lineHeight:'0.3rem', fontFamily:'Baskerville'}}>
                    ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi?&nbsp;
                    </span>
                </Marquee>
            </TextMove>
            <a href='https://maps.app.goo.gl/va5jCDSNcbdHHJvQ6'>
                <div style={{height:'95vh', backgroundImage:`url(${map})`, backgroundSize:'cover', backgroundPositionY:'10vh', backgroundPositionX:'-5vw'}}>
                </div>
            </a>
          
            
            <TextMove style={{bottom:'0', position:'absolute', bottom:"-10px"}}>
                <Marquee gradient={false} speed={100} direction="right" >
                    <span style={{fontSize:'0.2rem', lineHeight:'0.3rem', fontFamily:'Baskerville'}}>
                    ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi?&nbsp;
                    </span>
                </Marquee>
            </TextMove>
        </div>
    )
}

export default LocationMap
