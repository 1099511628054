import React from 'react'
import useSound from 'use-sound'
import { SoundTextM } from './styles/Text';

const SoundSticker = ({img, sound}) => {

    const [play, {stop}] = useSound(window.location.origin + sound , { volume: 0.5 });

    const playSound = () => {
        play();
    }

    const stopSound = () => {
        stop();
    }

    return (
      
            <SoundTextM onMouseEnter={playSound} onMouseLeave ={stopSound}>{img}</SoundTextM>

    )
}

export default SoundSticker
