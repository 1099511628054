import { useState } from "react"
import { GrClose } from "react-icons/gr"
import ModalShop from "./ModalShop"
import { GridBox, GridLine, MenuImg, EnlargedModal, EnlargedBox} from "./styles/Containers"
import { Spacer } from "./styles/Containers"
import { FBText, FBTag } from "./styles/Text"


const CocktailsGrid = () => {

    const [show, setShow] = useState(false)
    const [img, setImg] = useState('')
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')

    const Cocktails = [
        {
            name: 'Aberzombie',
            tagline: 'Not for the faint-hearted',
            img:'/media/cocktails/aberzombie.png',
            angle:'rotate(2.23deg)'
        },
        {
            name: 'Big Kahuna Mojito',
            tagline: "Mojito fan? This one's for you",
            img:'/media/cocktails/big_kahuna_mojito.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Black Mamba',
            tagline: 'Slick & Smooth, Gin Meets Blackbeery',
            img:'/media/cocktails/black_mamba.png',
            angle:'rotate(0.23deg)'
        },
        {
            name: 'Dark & Horny',
            tagline: 'Go hard or go home',
            img:'/media/cocktails/dark_n_horny.png',
            angle:'rotate(-2.23deg)'
        },
        {
            name: 'Johnny Pacheco',
            tagline: 'Boulevardier Latino',
            img:'/media/cocktails/johnny_pacheco.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Lava Lava Spritz',
            tagline: 'Our aperol Spritz twist',
            img:'/media/cocktails/lava_lava_spritz.png',
            angle:'rotate(-3.23deg)'
        },
        {
            name: 'Love Mule',
            tagline: 'Our Love Child',
            img:'/media/cocktails/love_mule.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Maka Maka',
            tagline: 'Colorful & Refreshing with a hint of Spice',
            img:'/media/cocktails/makamaka.png',
            angle:'rotate(1.123deg)'
        },
        {
            name: 'Mash Up',
            tagline: 'Vermut Meets Mojito',
            img:'/media/cocktails/mash_up_mojito_vermut.png',
            angle:'rotate(1.56deg)'
        }

    ]


    const enlarge = async (food, e) => {

        


        setName(food.name)
        setTag(food.tagline)
        setImg(food.img)
        setShow(true)
        console.log(e.style)
        
    }

    return (
        <GridBox>

                <GridLine>
                    <MenuImg src={window.location.origin + Cocktails[0].img}  alt="1" style={{transform:'rotate(2.23deg)'}} onClick={(target) => enlarge(Cocktails[0], target)} /> 
                    <MenuImg src={window.location.origin + Cocktails[1].img} style={{transform:'rotate(0.9deg)'}} onClick={() => enlarge(Cocktails[1])}/> 
                    <MenuImg src={window.location.origin + Cocktails[2].img} style={{transform:'rotate(-1.2deg)'}} onClick={() => enlarge(Cocktails[2])}/> 
                </GridLine>
                

                <GridLine>
                <MenuImg src={window.location.origin + Cocktails[3].img} style={{transform:'rotate(3.23deg)'}} onClick={() => enlarge(Cocktails[3])}/> 
                <MenuImg src={window.location.origin + Cocktails[4].img} style={{transform:'rotate(-1.7deg)'}} onClick={() => enlarge(Cocktails[4])} /> 
                <MenuImg src={window.location.origin + Cocktails[5].img} style={{transform:'rotate(-0.5deg)'}} onClick={() => enlarge(Cocktails[5])}/> 
                </GridLine>
            


                <GridLine>
                <MenuImg src={window.location.origin + Cocktails[6].img}  style={{transform:'rotate(1.76deg)'}} onClick={() => enlarge(Cocktails[6])} /> 
                <MenuImg src={window.location.origin + Cocktails[7].img} style={{transform:'rotate(0.34deg)'}} onClick={() => enlarge(Cocktails[7])} /> 
                <MenuImg src={window.location.origin + Cocktails[8].img} style={{transform:'rotate(-3.12.0deg)'}} onClick={() => enlarge(Cocktails[8])}/>
                </GridLine>

                {show && <ModalShop img={img} show={show} setShow={setShow} name={name} tag={tag} />}
  
        </GridBox>
    )
}

export default CocktailsGrid
