import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
import { SliderDiv } from './styles/Containers';
import slide1 from '../images/slider/Slide_1.jpg'
import slide2 from '../images/slider/Slide_2.jpg'
import slide3 from '../images/slider/Slide_3.jpg'
import slide4 from '../images/slider/Slide_4.jpg'
import slide5 from '../images/slider/Slide_5.jpg'
import slide6 from '../images/slider/Slide_6.jpg'
import slide7 from '../images/slider/Slide_7.jpg'
import slide8 from '../images/slider/Slide_8.jpg'
import slide9 from '../images/slider/Slide_9.jpg'
import slide10 from '../images/slider/Slide_10.jpg'



const MakaSlider = () => {

   
    return (
        <div style={{height:'100vh', width:'100vw', backgroundColor:'black'}} className="slider-hand">
            <Swiper
            slidesPerView={1}
            loop={true}
            modules={[Pagination,Autoplay]}
            autoplay={{delay: 3000}}
        >
            <SwiperSlide>
                <SliderDiv src={slide1}>
                </SliderDiv>
            </SwiperSlide>
            <SwiperSlide>
                <SliderDiv src={slide2}>
                </SliderDiv>
            </SwiperSlide>
            <SwiperSlide>
                <SliderDiv src={slide3}>
                </SliderDiv>
            </SwiperSlide>
            <SwiperSlide>
                <SliderDiv src={slide4}>
                </SliderDiv>
            </SwiperSlide>
            <SwiperSlide>
                <SliderDiv src={slide5}>
                </SliderDiv>
            </SwiperSlide>
            <SwiperSlide>
                <SliderDiv src={slide6}>
                </SliderDiv>
            </SwiperSlide>
            <SwiperSlide>
                <SliderDiv src={slide7}>
                </SliderDiv>
            </SwiperSlide>
            <SwiperSlide>
                <SliderDiv src={slide8}>
                </SliderDiv>
            </SwiperSlide>
            <SwiperSlide>
                <SliderDiv src={slide9}>
                </SliderDiv>
            </SwiperSlide>
            <SwiperSlide>
                <SliderDiv src={slide10}>
                </SliderDiv>
            </SwiperSlide>
        </Swiper>
      </div>
    )
}

export default MakaSlider
