import styled from "styled-components";
import { device } from './Device';
import { keyframes } from "styled-components";
import hand from "../../hand.png"
import handBlack from "../../hand_black.png"
import mar from '../../gifs/mar.gif'
import welcome from '../../images/welcome.jpg'
import tira_black from '../../images/TIRA_papel_02_crop.png'
import tira_white from '../../images/TIRA_papel_01_crop.png'
import collage from '../../images/home_banner.jpg'
import texture from '../../images/texture.jpg'
import maka_noise from '../../images/maka_noise.jpg'
import { TinderInfo, TinderInfoM } from "./Text";

export const Spacer = styled.div`
    height: ${props => props.space};
`
export const HomeBanner = styled.div`
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    /* cursor: url(${hand}) 15 15, auto; */
    
`

export const HomeBanner2= styled.div`
    height: 100vh;
    width: 100vw;
    background-image: url("/media/general/naked_2.png");
    background-position: center;
    background-size: cover;
    overflow: hidden;
    border-top:1px solid block;
    /* cursor: url(${hand}), auto; */
`
export const FBMainDiv = styled.div`
    height: 100vh;
    width: 100vw;
    background-image: url(${props=> props.src});
    background-position: center;
    background-size: cover;
    overflow: hidden;
    border-top:1px solid block;
    /* cursor: url(${hand}), auto; */
`

const EnterMenu= keyframes`

    from {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(0%);
    }
` 

const LeaveMenu= keyframes`

    from {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(0%);
    }
    to {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
` 

export const MainMenuBox = styled.div`
    height:100vh;
    position: absolute;
    z-index: 100;
    top: 0;
    right:0;
    background: url(${mar});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap:6vh;
    justify-content: center;
    text-align: center;
    transition: all 0.7s ease-in-out;
    /* animation: ${(props => props.show) ? EnterMenu : LeaveMenu} 0.5s ease-in;   */

    @media ${device.mobileS} {
        width:100vw; 
    }

    @media ${device.mobileL} {
        width:100vw; 
    }


    @media ${device.tablet} {
        /* transition: all 1s linear; */
        width: 100vw !important; 
    }

`

export const MenuItemBox = styled.div`
    display:flex; 
    flex-direction:column; 
    gap:4vh; 
    height:70vh; 
    position: relative;

    @media ${device.mobileS} {
        width:100vw; 
    }

    @media ${device.tablet} {
        width:100vw; 
    }

    @media ${device.laptop} {
        transition: all 1s linear;
        width:100vw; 
    }

`

export const WelcomeBox = styled.div`
    position: relative;
    height:100vh; 
    width:100vw; 
    display:flex;
    flex-direction:column; 
    justify-content:center; 
    text-align:center; 
    align-items:center; 
    line-height:1.1;
    /* background-color: red;*/
    /* background-image: url("/media/general/welcome.jpg"); */
    background-image: url(${props=> props.src || collage});
    background-position: center;
    background-size: cover;
    

    @media ${device.mobileS} {
        padding-top: 1vh;
    }


    @media ${device.mobileL} {
        padding-top: 2vh;
    }


    @media ${device.laptop} {
        padding-top: 3vh;
    }

    @media ${device.desktop} {
        padding-top: 5vh;
    }

 

`
export const PolaroidWhereBox = styled.div`
    display: flex;
    justify-content:center; 
    align-items:center;
    position: relative;

    @media ${device.mobileS} {
        width:100%;
    }

    @media ${device.tablet} {
        width:50%;
    }

    @media ${device.laptop} {
        width:50%;
    }
`

export const PolaroidBox = styled.div`
height:80vh;
width: 100%;
position:relative; 
display:flex;
justify-content: center;
align-items: center;

/* :hover {
    cursor: pointer;
} */


    @media ${device.mobileS} {
        padding-left: 5vh;
        width:40vw; 
        margin-top: -10vh;
        margin-bottom: 5vh;
    }

    @media ${device.tablet} {
        padding-left: 0;
        width:30vw; 
        margin-top:10vh; 
    }

    @media ${device.laptop} {
        padding-left: 0;
        width:30vw; 
    }


`

export const FamilyBox = styled.div`
    position: relative;
    padding:10vh 0vw;
    /* background: url(${maka_noise}); */
    padding-bottom:0;
    display:flex;
    flex-direction:column; 
    justify-content:center; 
    text-align:center; 
    align-items:center; 
    line-height:1.1;
`
export const FooterBox = styled.div`
    
    /* background: url(${texture}); */
    background-color: #F9F3E5;
    background-position: center;
    background-position-y: -12vh;
  
    display:flex; 
    justify-content:space-around; 
    padding-top:2vh; 
    padding-bottom:2vh;

    @media ${device.mobileS} {
        display: flex;
        flex-direction: column;
        //height:30vh; 
    }

    @media ${device.tablet} {
        display: flex;
       flex-direction: row;
       /* height:40vh;  */
    }

    @media ${device.laptop} {

    }
`

export const FooterWhere = styled.div`
    
    @media ${device.mobileS} {
        display:none;
    }

    @media ${device.tablet} {
       
    }

    @media ${device.laptop} {
        display:flex; 
        flex-direction:column; 
        gap:1vh;
        margin-top:5vh;
    }
`

export const FooterSocial = styled.div`

    @media ${device.mobileS} {
        display:none;
    }

    @media ${device.laptop} {
        display:flex; 
        flex-direction:column; 
        vertical-align:top; 
        gap:0vh; 
        margin-top:5vh;
    }

`

export const MenuBox = styled.div`
    position: relative;
    padding-top:15vh;
    padding-bottom:10vh;
    width:100vw; 
    display:flex;
    flex-direction:column; 
    justify-content:center; 
    text-align:center; 
    align-items:center; 

    @media ${device.mobileS} {
        padding-left: 1vw;
    }
`

export const FBBox =  styled.div`
    height:100vh;
    width:50vw;
`

const scrollLeft= keyframes`

    from {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
` 
 export const BoxMove = styled.div`
   background-image: url(${tira_black});
   background-size: 100% 100%;
   background-position: center center;
   background-color: transparent;
 `
export const TextMove = styled.div`
        /* background-color: #EBE9DC; */
        background-image: url(${tira_black}) ;
        background-repeat: no-repeat;
        background-size: cover;
        font-family: Garamond;
        font-weight: 600;
        /* text-transform:uppercase;
        line-height:0.5rem;
        font-weight:600;
        font-size: 0.18rem; */
        font-size: 0.25rem;
        position: absolute;
        bottom:0;
        z-index: 10;
        padding:1.5vh;
        color:#eae2d2;
        /* border:2px solid black; */


        @media ${device.mobileS} {
            font-size: 0.10rem;
            height:5vh;
            ${props => props.mob}
        }


        @media ${device.mobileL} {
            font-size: 0.15rem; 
        }


        @media ${device.tablet} {
            font-size: 0.20rem;
        }

        @media ${device.laptop} {
            font-size: 0.25rem;
            height:5vh;
            ${props => props.pc} 
        }

`

export const TextMoveWhite = styled(TextMove)`
    background-image: url(${tira_white}) ;
    color:#0b1321;
    height: 4vh;
    
    @media ${device.mobileS} {
        ${props => props.mob} 
    }

    @media ${device.mobileL} {
        ${props => props.tab} 
    }

    @media ${device.tablet} {
        ${props => props.pc} 
    }

    @media ${device.laptop} {
        ${props => props.pc}
    }
    
`

export const TextMoveM = styled.div`
        background-color: #EBE9DC;
        height:5vh;
        font-family: Garamond;
        font-weight: 600;
        font-size: 0.25rem;
        position: absolute;
        bottom:0;
        z-index: 10;
        border:2px solid black;

`



const Orbit = keyframes`

    from {
      /* transform: rotate(0deg) translateX(100px) rotate(360deg); */
      transform: rotate(0deg);
    }
    to {
      /* transform: rotate(360deg) translateX(400px) rotate(-360deg); */
      transform: rotate(360deg);
    }
    `  

  

export const MenuStickers = styled.img`
    cursor: url(${handBlack}), auto;

    :hover{
        transform: rotate(-10deg);
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    /* @media ${device.tablet} {
            max-width: 35%;
    } */


`
export const Stickers = styled.img`
    cursor: url(${handBlack}), auto;
    transition: all 0.2s linear;
    
    :hover{
        transform: rotate(-10deg) !important;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
`

export const WhereBox = styled.div`
    display:flex; 
    height:100vh; 
    width:100vw;

    @media ${device.mobileS} {
        height:auto;
        flex-direction: column-reverse;

    }

    @media ${device.tablet} {
        height:auto;
        flex-direction: row;

    }

    @media ${device.laptop} {
        height:100vh;
    }

`

export const WhereContentBox = styled.div`
    padding:15vh 5vw;
    font-family:Garamond;   

    @media ${device.mobileS} {
        width:90%;
    }

    @media ${device.tablet} {
        width:50%;
    }

    @media ${device.laptop} {
        width:50%;
    }
`

export const HoverImgWhere = styled.img`
    position: absolute;
    height:auto;
    transition: all 0.2s ease-in-out;
    margin-top: 10vh;
    cursor: url(${handBlack}), auto;


    :hover{
        cursor: url(${handBlack}), auto;
        /* transform: rotate(-40deg) !important;
        transform: translateX(20%); */
        /* height: 110%;
        margin-top: 0;
        margin-left:0vw;
        transform: rotate(-10deg);
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%; */
    }


    @media ${device.mobileS} {
        width: 50vh;

    }

    @media ${device.tablet} {
        width: 50vh;

    }

    @media ${device.laptop} {
        width: 60vh;
    }
`


export const HoverImg = styled.img`
    position: absolute;
    height:auto;
    transition: all 0.5s ease-in-out;
    margin-top: 10vh;
    cursor: pointer;


    :hover{
        cursor: url(${handBlack}), auto;
        /* transform: rotate(-40deg) !important;
        transform: translateX(20%); */
        /* height: 110%;
        margin-top: 0;
        margin-left:0vw;
        transform: rotate(-10deg);
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%; */
    }


    @media ${device.mobileS} {
        width: 40vh;
        margin-left: 15.5vw;
    }

    @media ${device.tablet} {
        width: 45vh;
        margin-left: 5.5vw;
    }

    @media ${device.laptop} {
        width: 45vh;
        margin-left: 7.5vw;
    }
`
// Family Section

export const FamilyPhotos = styled.div`
    display:flex; 
    gap:2vw; 
    padding-left:10vw; 
    padding-right:10vw;


    /* @media ${device.mobileS} {
        height:300vh;
    } */

    /* @media ${device.tablet} {
        height:100vh; 
    } */

    /* @media ${device.laptop} {
        height:120vh; 
    } */

`

export const PhotoGrid = styled.div`

    display:flex; 
    gap:2vw;

    img:hover{
        cursor: url(${handBlack}), auto;
        /* transform: rotate(-4deg) !important;
        transform: translateX(20%); */
        transition: all 0.1s ease-out;
        /* margin: 3vw; */
        transform: scale(1.2);
        rotate: 4deg;
        color : black ;
    }

    @media ${device.mobileS} {

        flex-direction: column;
        
        img {
                width:60vw;
                height:auto;
            }
    }

    @media ${device.tablet} {

        flex-direction: row;

        img {
            width:27vw;
            height:auto;
        }

    }

    @media ${device.laptop} {
  
        flex-direction: row;

        img {
            width:27vw;
            height:auto;
        }
    }
`

// Food Section
export const GridBox = styled.div`
    width:100vw; 
    padding:0 5vw; 
    position:relative;
`

export const GridLine = styled.div`
    display:flex; 
    justify-content:center; 
    text-align:center;

    @media ${device.mobileS} {
        flex-direction: column;
    }

    @media ${device.tablet} {
        flex-direction: row;
    }

    @media ${device.laptop} {
        flex-direction: row;
    }
`

export const MenuImg = styled.img`
    height: auto;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border:1vh solid white;
    box-shadow: 10px 2vh 5vh rgba(0,0,0,0.4);

    &:hover {
        cursor: url(${handBlack}), auto;
        transform: rotate(-4deg) !important;
        transform: translateX(20%);
    }
        

    @media ${device.mobileS} {
        margin-left:15vw;
        width:70vw;
    }

    @media ${device.tablet} {
        margin-left: 0vw;
        width:25vw;
    }

    @media ${device.laptop} {
        width:30vw;
    }
       
  
`

export const MenuImgM = styled.img`
    height: 40vh;
    width:auto;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border:1vh solid white;
    box-shadow: 10px 2vh 5vh rgba(0,0,0,0.4);
  
`

export const EnlargedModal = styled.div`
    background-color:rgba(0,0,0,0.1);
    height:100vh; 
    width:100vw; 
    position:fixed; 
    z-index:2; 
    top:0%; 
    left:0%; 
  

    @media ${device.mobileS} {
        display: none;
    }

    @media ${device.tablet} {
        display: flex;
        flex-direction:column; 
        justify-content:center; 
        align-items:center;
    }


`

export const EnlargedBox = styled.div`
    height: 85vh;
    width: 50vw;
    position: relative;
    transition: all 1s ease-in-out;
    //cursor: pointer;
    background-image: url(${props=> props.src});
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
    border: 1vh solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-shadow: 2vh 2vh 5vh grey;
    overflow: hidden;
    z-index: 5;

    span {
        cursor: pointer;
    }
`

// FB Selector

export const FoodBox = styled.div`
    height:100vh; 
    width:50vw; 
    position:relative;  
    overflow:hidden;
    /* background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid black; */

`

export const PlaylistBanner = styled.div`
    height: 55vh;
    width: 100vw;
    background-image: url("/media/songs/maka_tape.png");
    background-position: center;
    background-size: cover;
    overflow: hidden;
    display: flex;
    border-top: 3px solid black;
    border-bottom: 3px solid black;
    
    

    /* div {
        white-space: nowrap;
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -moz-animation: ${scrollLeft} 1s linear infinite;
        -webkit-animation: ${scrollLeft} 1s linear infinite;
        animation: ${scrollLeft} 15s linear infinite;  

        :hover {
            cursor: pointer;
        }
    } */
   
`

export const CensoredBox = styled.div`
    //height:70vh; 
    width:100vw; 
    position:relative; 
    overflow:hidden; 
    z-index:0;

    /* @media ${device.mobileS} {
        height:25vh; 
    }

    @media ${device.mobileM} {
        height:35vh; 
    }

    @media ${device.mobileL} {
        height:35vh; 
    }


    @media ${device.tablet} {
        height:60vh; 
    }

    @media ${device.laptop} {
        height:80vh; 
    } */
`

export const WomanBox = styled.div`
    height:100%; 
    width:auto; 
    position:relative;
    /* top:-18vh; */

    @media ${device.mobileS} {
        top:5vh;
    }

    @media ${device.mobileM} {
        top:5vh;
    }

    @media ${device.mobileL} {
        top:5vh;
    }

    @media ${device.tablet} {
        top:-5vh;
    }


    @media ${device.laptop} {
        top:-7vh;
    }

    @media ${device.desktop} {
        top:-14vh;
    }
`

// Shop Section

export const ShopBox = styled.div`
    display:flex; 
    width:100vw;


    @media ${device.mobileS} {
        height:auto;
        flex-direction: column;
        padding-bottom: 15vh;

    }

    @media ${device.tablet} {
        height:110vh;
        flex-direction: row;
        padding-bottom: 3vh;
        padding-right: 5vh !important;
    }

    @media ${device.laptop} {
        height:100vh;
        flex-direction: row;
        padding-right: 0vh;
    }

`
export const ShopContentBox = styled.div`
    /* height:100%;  */
    width:45%; 
    padding:5vh; 
    text-align:left;

    @media ${device.mobileS} {
        width:90%; 
        margin-top:5vh; 
    }

    @media ${device.tablet} {
        width:30%; 
        margin-top:15vh; 
    }

    @media ${device.laptop} {
        width:40%; 
        margin-top:15vh; 
    }
`

export const ShopPolaroidBox = styled.div`
    display:flex; 
    height:100%; 
    position:relative;

    @media ${device.mobileS} {
        width:100%;
    }

    @media ${device.tablet} {
        width:70%;
    }

    @media ${device.laptop} {
        width:60%;
    }
`

export const Cassette = styled.img`
cursor: url(${handBlack}), auto;

:hover{
        transform: rotate(-10deg);
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
`

// Tinder Game

export const TinderGame = styled.div`
    /* background-image: url("/media/maka/Background.png"); */
    height:100vh;
    width: 100vw;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
`

export const MTinderGame = styled.div`
    /* background-image: url("/media/maka/Background.png"); */
    height:100vh;
    width: 100vw;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    padding:2vh;
`


// Mobile

export const MenuMobile = styled.div`
    /* transform: ${({ open }) => open ? 'translateX(0vw)' : 'translateX(100vw)'}; */
    height:100vh;
    width: 100vw;
    position: absolute;
    z-index: 100;
    top: 0;
    right:0;
    background: url(${mar});
    background-repeat: no-repeat;
    background-size:cover;
    /* background: url('media/general/texture.jpg'); */
    /* background-position-y: -10vh;
    background-position-x: -20vh; */
    display: flex;
    flex-direction: column;
    gap:6vh;
    justify-content: center;
    text-align: center;
    /* animation: ${EnterMenu} 0.5s  ease-in-out;   */
    
    transition: all 0.6s ease-in-out;
`

// Cookie Banner

export const CookieMainDiv = styled.div`
    position:fixed; 
    background-color:black;
    top:0;
    left:0; 
    height:100vh; 
    width:100vw; 
    z-index:100;
    display:flex; 
    justify-content:center; 
    align-items:center;
`

export const CookieModal = styled.div`
    position:relative; 
    height:50%; 
    width:70%; 
    display:flex; 
    flex-direction:column; 
    justify-content:center; 
    text-align:center;
    align-items:center; 
    gap:3vh;
    cursor: url(${hand});
`


export const CarnetDiv = styled.div`
    display:flex;
    justify-content:center; 
    align-items:center;
    position: relative;

    @media ${device.mobileS} {
        flex-direction: column;
    }

    @media ${device.tablet} {

        flex-direction: row;

    }

    /* @media ${device.laptop} {
        flex-direction: row; 
    } */

` 

export const CarnetImage = styled.img`
    width:15vw;
    height:auto;
`

export const MakaCarnet = styled.div`
    display:hidden;
    width:25vw; 
    /* height:25vh; */
    padding:2vh; 
    opacity:1;
    position: relative;
    background-image:url(${props => props.carnet});
    background-size:cover;
    /* background-repeat: no-repeat; */
`



/* Tinder Mobile */

export const GifBox = styled.div`
    position:fixed; 
    z-index:2;
    height:100vh; 
    width:100vw; 
    margin-top: -4vh;
    margin-left: -9vw;
    background-image:url(${props => props.gif}); 
    background-repeat:no-repeat; 
    background-size:cover;
    /* background-position-x:-20vw;  */
    background-position:right; 
`

export const MakaFormM = styled.form`
    height: 100vh;
    display:flex; 
    text-align:center; 
    justify-content:center; 
    align-items:center;
    overflow-y: scroll; 
`

export const MakaFormHeroM = styled(MakaFormM)`
    height: 100%;
    padding-bottom: 5vh;
`

export const CarnetBoxM = styled.div`
    display:flex; 
    /* flex-direction: row; */
    flex-direction: column;
    /* width: 100vw; */
    gap:2vh;
    justify-content:center; 

`
export const CarnetImageM = styled.img`
    /* width:30vw; */
    width:50vw;
    height:auto;
`

export const MakaCarnetM = styled.div`
    display:hidden;
    /* margin-left:8vw; */
    margin-top:5vh;
    margin-bottom:2vh;
    width:75vw; 
    height:25vh;
    padding:2vh; 
    position: relative;
    background-image:url(${props => props.carnet});
    background-size:contain;
    background-repeat: no-repeat;
`

export const CenterDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

    div>${TinderInfo}{
        color: black;
    }
    div>${TinderInfoM}{
        color: black;
    }
`

export const SliderDiv = styled.div`
    height: 100vh;
    width:100vw;
    background-image: url(${props=> props.src});
    background-position: center;
    background-size: cover;
`
export const SliderDivM = styled.div`
    height: 100vh;
    width:100vw;
    background-image: url(${props=> props.src});
    background-position: center;
    background-size: cover;
`