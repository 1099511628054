import {useEffect} from 'react'
import { useState } from 'react'
import { FBMainDiv, HomeBanner2, FBBox } from './styles/Containers'
import UseMouse from './UseMouse'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { FoodBox, Stickers } from './styles/Containers'
import useSound from 'use-sound'
import { NavLink } from 'react-router-dom'
import fb_bw from '../images/fb_black_white.jpg'
import fb_drink from '../images/fb_drink.jpg'
import fb_burger from '../images/fb_burger.jpg'

const FBSelector = () => {

    const [src, setSrc] = useState(fb_bw)


    const [playHungry] = useSound(window.location.origin + '/media/sounds/hungry.mp3' , { volume: 0.5 });
    const [playThirsty, {stop}] = useSound(window.location.origin + '/media/sounds/thirsty.mp3' , { volume: 0.5 });


    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])



    return (
        <FBMainDiv style={{display:'flex'}} src={src} id='menu'>
            <NavLink to='/thirsty/cocktails'>
                <FoodBox  onMouseOver={ () => { playThirsty(); setSrc(fb_drink)} } onMouseOut={()=>  stop() } className='wave' >
                    <Stickers src={window.location.origin + '/media/stickers/thirsty.png'} data-aos="fade-right" className='food-sticker' height='20%' width='auto' style={{position:'absolute', top:'10vh', left:'6vw'}} />
                    {/* <img src={window.location.origin + '/media/general/hand.png'} height='8%' width='auto' style={{position:'absolute', top:Y, left:X}} /> */}
                </FoodBox>
            </NavLink>
            <NavLink to='/hungry/burgers' >
                <FoodBox onMouseOver={()=>{ playHungry(); setSrc(fb_burger)} } className='wave'>
                    <Stickers src={window.location.origin + '/media/stickers/hungry.png'} data-aos="fade-left" className='food-sticker' height='20%' width='auto' style={{position:'absolute', top:'5vh', left:'16vw'}} />
                    {/* <img src={window.location.origin + '/media/general/hand.png'} height='8%' width='auto' style={{position:'absolute', top:Y, left:(X-x2), transform:'rotate(135deg)'}} /> */}
                </FoodBox>
            </NavLink>
        </FBMainDiv>
    )
}

export default FBSelector
