import styled from "styled-components";
import handBlack from "../../hand_black.png"
import { device } from './Device';



// Form Divs
export const FormMainDiv = styled.div`
    /* height:200vh; */
    width:100vw; 
    position:relative;
    background-image: url("/media/general/family_form.jpg");
    background-position: center;
    background-position-y: -10vh;
    background-size: cover;

    @media ${device.mobileS} {
        height:140vh;
    }
    @media ${device.tablet} {
        height:100vh; 
    }
    @media ${device.laptop} {
        height:100vh; 
    }

`       

export const FormBox = styled.div`
    margin-top:5vh;
    position:absolute; 
    z-index:2; 
    top:15%; 
    left:10%; 
    width:80%;
    display: flex; 
    flex-direction:column;
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const FormTitle = styled.div`
    font-size: clamp(0.05rem, 2vw + 0.3rem, 2rem);
    color:white;
    font-family: ITC_Bold;
    text-transform: uppercase;

    @media ${device.mobileS} {
        width:95%;
        font-size: clamp(0.05rem, 1vw + 0.3rem, 2rem);
    }
    @media ${device.tablet} {
        font-size: clamp(0.05rem, 2vw + 0.3rem, 2rem);
        width:90%;
    }
    @media ${device.laptop} {
        width:75%;
    }
`


export const JoinForm = styled.form`
    margin-left:10%;
    display:flex; 
    justify-content:space-between;
    margin-left: -5vw;
    /* height:100%; 
    width:100%; */

    @media ${device.mobileS} {
        width:95%;
        font-size: clamp(0.05rem, 1vw + 0.3rem, 2rem);
        flex-direction: column;
    }

    @media ${device.tablet} {
        font-size: clamp(0.05rem, 2vw + 0.3rem, 2rem);
        width:90%;
        flex-direction: row;
        gap:5vw;
    }


    @media ${device.laptop} {
        width:75%;
        flex-direction: row;
        gap:5vw;
    }

`

export const FormColumn = styled.div`
    text-align:left; 
    height:100%; 
    width:50%;
`
export const QuestionDiv = styled.div`

    color: white;

    /* span{
        width:30vw;
        font-size: 3vh;
    }  

    input {
        height:5vh;
        width:30vw;
        background-color: rgba(255,255,255,0.5);
        border-radius: 2vh;
        border:none;
    } */


    @media ${device.mobileS} {
        
        font-size: clamp(0.05rem, 0.5vw + 0.2rem, 0.25rem);
        width:85vw;
        /* margin-bottom: 8vh; */

        span{
            width:80vw;
            font-size: 3vh;
        }  

        input {
            height:5vh;
            width:80vw;
            background-color: rgba(255,255,255,0.5);
            border-radius: 2vh;
            border:none;
        }
    }

    @media ${device.mobileL} {
        
        font-size: clamp(0.05rem, 0.5vw + 0.2rem, 0.25rem);
        width:75vw;
        /* margin-bottom: 8vh; */

        span{
            width:70vw;
            font-size: 3vh;
        }  

        input {
            /* height:5vh; */
            width:70vw;
            background-color: rgba(255,255,255,0.5);
            border-radius: 2vh;
            border:none;
        }
    }

    @media ${device.tablet} {
        font-size: clamp(0.05rem, 0.5vw + 0.2rem, 0.25rem);
        width:35vw;
        margin-bottom: 0vh;
        
        span{
            width:30vw;
            font-size: 3vh;
        }  

        input {
            height:5vh;
            width:30vw;
            background-color: rgba(255,255,255,0.5);
            border-radius: 2vh;
            border:none;
        }
    }


    @media ${device.laptop} {

        font-size: clamp(0.05rem, 0.5vw + 0.2rem, 0.25rem);
        width:35vw;
        
        span{
            width:30vw;
            font-size: 3vh;
        }  

        input {
            height:5vh;
            width:30vw;
            background-color: rgba(255,255,255,0.5);
            border-radius: 2vh;
            border:none;
        }
    }

`
// export const LabelField = styled.label`
//     background-color: blue;

//     span {
//         font-family: Garamond;
//         font-weight: bold;
//         color:white;
//         font-size: clamp(0.1rem, 1vw + 0.2rem, 0.25rem);
//     }

//     input {
//         height:5vh;
//         width:30vw;
//         background-color: rgba(255,255,255,0.5);
//         border-radius: 2vh;
//         border:none;
//     }
// `


// Form Mobile

export const FormMainDivM = styled.div`
    height:110vh;
    width:100vw; 
    position:relative;
    background-image: url("/media/general/family_form_mobile.jpg");
    background-position: center;
    background-size: cover;

`    

export const FormBoxM = styled.div`
    margin-top:5vh;
    position:absolute; 
    z-index:2; 
    top:5%; 
    left:10%; 
    width:80%;
    display: flex; 
    flex-direction:column;
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const JoinFormM = styled.form`
    margin-top: 20%;
    display:flex; 
    justify-content:space-between;
    margin-left: -5vw;
    /* height:100%; 
    width:100%; */
`

export const LabelFieldM = styled.label`

    span {
        font-size: 3vh;
        
        font-family: Garamond;
        font-weight: bold;
        color:white;
    }

    input {
        height:5vh;
        width:100%;
        background-color: rgba(255,255,255,0.5);
        border-radius: 2vh;
        border:none;
    }
`


// Buttons For Random Use
export const BtnFood = styled.button`
    background-color: rgba(249,243,229,1);
    border: 1px solid black;
    border-radius: 1.5vh;
    font-size: 3vh;
    padding:1vh 3vh;
    font-family: Garamond;

    :hover {
        background-color: black;
        color:white;
        cursor: url(${handBlack}), auto;
    }

    :active{
        background-color: black;
        color:white;
    }
`

export const BtnFoodM = styled.button`
    background-color: rgba(249,243,229,1);
    border: 1px solid black;
    border-radius: 1vh;
    font-size: 2.5vh;
    padding:1vh 3vh;
    font-family: Garamond;

    :hover {
        background-color: black;
        color:white;
        cursor: url(${handBlack}), auto;
    }

    :active{
        background-color: black;
        color:white;
    }

    :visited{
        background-color: rgba(249,243,229,1);
        border: 1px solid black;
        border-radius: 1vh;
        font-size: 2.5vh;
        padding:1vh 3vh;
        font-family: Garamond;
    }
`