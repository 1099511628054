import { useState } from "react"
import { GridBox, GridLine, MenuImg, EnlargedModal, EnlargedBox} from "./styles/Containers"
import { Spacer } from "./styles/Containers"
import { FBText, FBTag } from "./styles/Text"
import {GrClose} from 'react-icons/gr'
import ModalShop from "./ModalShop"


const BurgerGrid = () => {

    const [show, setShow] = useState(false)
    const [img, setImg] = useState('')
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')

    const Burgers = [
        {
            name: 'BAD ASS',
            tagline: "OU`ANGUS YOUNG' BURGER",
            img:'/media/burgers/bad_ass.png',
            angle:'rotate(2.23deg)'
        },
        {
            name: 'Bean Machine!',
            tagline: 'Do me a solid',
            img:'/media/burgers/bean_machine.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'The Game Changer',
            tagline: 'Dig In, Man',
            img:'/media/burgers/the_game_changer.png',
            angle:'rotate(0.23deg)'
        },
        {
            name: 'Naked',
            tagline: 'Nothing but the naked true!',
            img:'/media/burgers/naked.png',
            angle:'rotate(-2.23deg)'
        },
        {
            name: 'Hot Mama 2.0',
            tagline: 'Cheesier Than Ever',
            img:'/media/burgers/hot_mama.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'The Dude',
            tagline: 'Dig In, Man',
            img:'/media/burgers/the_dude.png',
            angle:'rotate(-3.23deg)'
        },
        {
            name: 'The Nice Guy',
            tagline: "He's a keeper(and a veggie too)",
            img:'/media/burgers/the_nice_guy.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Maradona',
            tagline: 'The Legend is back',
            img:'/media/burgers/maradona.png',
            angle:'rotate(1.123deg)'
        },
        {
            name: 'Big Maka',
            tagline: 'The Ultimate Beach Burger',
            img:'/media/burgers/big_maka.png',
            angle:'rotate(1.56deg)'
        }

    ]


    const enlarge = async (food) => {

        setName(food.name)
        setTag(food.tagline)
        setImg(food.img)
        setShow(true)
        
    }

    return (
        <GridBox>

                <GridLine>
                    <MenuImg src={window.location.origin + Burgers[0].img}  alt="1" style={{transform:'rotate(2.23deg)'}} onClick={(target) => enlarge(Burgers[0], target)} /> 
                    <MenuImg src={window.location.origin + Burgers[1].img} style={{transform:'rotate(0.9deg)'}} onClick={() => enlarge(Burgers[1])}/> 
                    <MenuImg src={window.location.origin + Burgers[2].img} style={{transform:'rotate(-1.2deg)'}} onClick={() => enlarge(Burgers[2])}/> 
                </GridLine>
                

                <GridLine>
                    <MenuImg src={window.location.origin + Burgers[3].img} style={{transform:'rotate(3.23deg)'}} onClick={() => enlarge(Burgers[3])}/> 
                    <MenuImg src={window.location.origin + Burgers[4].img} style={{transform:'rotate(-1.7deg)'}} onClick={() => enlarge(Burgers[4])} /> 
                    <MenuImg src={window.location.origin + Burgers[5].img} style={{transform:'rotate(-0.5deg)'}} onClick={() => enlarge(Burgers[5])}/> 
                </GridLine>
            


                <GridLine>
                    <MenuImg src={window.location.origin + Burgers[6].img}  style={{transform:'rotate(1.76deg)'}} onClick={() => enlarge(Burgers[6])} /> 
                    <MenuImg src={window.location.origin + Burgers[7].img} style={{transform:'rotate(0.34deg)'}} onClick={() => enlarge(Burgers[7])} /> 
                    <MenuImg src={window.location.origin + Burgers[8].img} style={{transform:'rotate(-3.12.0deg)'}} onClick={() => enlarge(Burgers[8])}/>
                </GridLine>

                {show && <ModalShop img={img} show={show} setShow={setShow} name={name} tag={tag} />}
  
        </GridBox>
    )
}

export default BurgerGrid
