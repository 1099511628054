import { useEffect, useState } from 'react'
import MainMenu from '../../components/Mobile/MainMenu'
import Banner from '../../components/Mobile/Banner'
import Welcome from '../../components/Mobile/Welcome'
import WhereBlock from '../../components/Mobile/WhereBlock'
import FBSelector from '../../components/Mobile/FBSelector'
import Shop from '../../components/Mobile/Shop'
import Censored from '../../components/Mobile/Censored'
import Playlist from '../../components/Mobile/Playlist'
import Family from '../../components/Mobile/Family'
import LocationMap from '../../components/Mobile/LocationMap'
import Footer from '../../components/Mobile/Footer'
import Intro from '../../components/Mobile/Intro'
import { Widget } from '@typeform/embed-react'
import MakaSlider from '../../components/Mobile/MakaSlider'
import FriendGau from '../../components/FriendGau'

// import LocationMap from '../components/Mobile/LocationMap'
// import Footer from '../components/Mobile/Footer'


// import Censored from '../components/Censored'
// import Shop from '../components/Shop'
// import Playlist from '../components/Playlist'




const Home = () => {

    const [show, setShow] = useState(true)

    const IntroSession = () => {
        setShow(false)
        window.sessionStorage.setItem("intro", false)
    }

    useEffect (() => {
        let x = window.sessionStorage.getItem("intro")
        
        if ( x === 'false' ){
            setShow(false)
        }   
    },[])

    return (
        <div style={{width:'100vw', overflow:'hidden'}}>
           {/* {show && <CookiePreload onClick={() => setShow(false)}/>} */}
           { show && <Intro onClick={IntroSession} />}
            <MainMenu />
            {/* <Banner /> */}
            <Welcome />
            <MakaSlider />
            <FBSelector />
            <FriendGau />
            {/* <div style={{height:'100vh', width:'100vw'}}>
                <Widget id="lQ2nHtp7" style={{ width:'100%', height:'100%' }} />
            </div> */}
            <Playlist />
            <Family />
            <Censored />
            <div style={{height:'100vh', width:'100vw'}} id='family-form'>
                <Widget id="Pw3wb1Cf" style={{ width:'100%', height:'100%' }} />
            </div>
            <LocationMap />
            <Footer />
        </div>
    )
}

export default Home
