import React, { useState } from 'react'
import Advanced from '../../components/Mobile/Advanced'
import AnimatedPages from '../../components/AnimatedPages'
import { TinderInfoM } from '../../components/styles/Text'
import { GrClose } from 'react-icons/gr'
import Benefits from '../Mobile/Benefits'

// Make a little delay after card is swiped and then show the gif

export const Tinder = () => {
  const [Benefit, setBenefit] = useState(false)

      return (

      <AnimatedPages>
        <div style={{position:'relative'}}>
        {
            Benefit &&
            <div style={{position:"absolute", zIndex:10, backgroundColor:"#f4eee0", width:"90vw", height:"90vh", border:"1px solid black", margin:"5vh 5vw"}}>
              <GrClose onClick={() => setBenefit(false)} style={{zIndex:10, position:"absolute", top:"1vh", right:"1vh", fontSize:"5vh", cursor:"pointer"}}/>
              <Benefits enbed={true}/>
            </div>
        }
          
          <TinderInfoM style={{color:"pink"}}>
            <a onClick={() => setBenefit(true)} style={{position:'absolute', right:"1vw", top:"7vh", rotate:"8deg", fontSize:"3.5vh", zIndex:2}}>Benefits you said ?</a>
          </TinderInfoM>


          <a href='/#home-banner'><img src={window.location.origin + '/media/logo.png'} style={{ position:'absolute', top:'1.5vh', left:'1.5vh', height:"6vh"}}/></a>
          <Advanced />
        </div>
      </AnimatedPages>
      )
}


export default Tinder
