import { useState, useEffect } from 'react'
import { SoundTextM } from '../styles/Text';
import Marquee from "react-fast-marquee"
import { WelcomeBox, Spacer, TextMove } from '../styles/Containers'
import { TextMoveM } from '../styles/Containers';
import UseMouse from '../UseMouse'
import Aos from 'aos'
import 'aos/dist/aos.css'
import collage from '../../images/home_mobile.jpeg'
import button_menu from '../../images/button_menu.png'
import button_reservas from '../../images/button_reservas.png'


const Welcome = () => {

    const { x, y} = UseMouse()

    const clickMenu = () => {
        window.open("/media/mm_menu.pdf","_blank");
    }

    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])

    return (
        <WelcomeBox  src={collage} style={{cursor:'none', height:'90vh'}}>
            <div style={{position:'relative', paddingTop:'0vh', display:'flex', flexDirection:'column', gap:'0.5vh'}} data-aos="fade-up">
                {/* <SoundTextM>Welcome</SoundTextM> */}
                {/* <span style={{ lineHeight:'4vh'}}>
                    <SoundTextM>ALL</SoundTextM>
                    <SoundTextM> TO</SoundTextM>
                </span> */}
                    <span style={{lineHeight:'4vh'}}><SoundTextM>MAKAMAKA</SoundTextM></span>
                <span style={{ lineHeight:'4vh'}}>
                    <SoundTextM>BEACH</SoundTextM>
                    <SoundTextM> BURGER</SoundTextM>
                </span>
                <span style={{lineHeight:'4vh'}}><SoundTextM>CAFE</SoundTextM></span>
            </div>
            <TextMove style={{zIndex:'1', bottom:"-10px"}}>
                <Marquee gradient={false} speed={100} >
                    <span style={{fontSize:'0.2rem', lineHeight:'0.3rem', fontFamily:'Baskerville'}}>
                        Estamos Abiertos! We are Open! Estamos Abiertos! We are Open! Estamos Abiertos! We are Open! Estamos Abiertos! We are Open! Estamos Abiertos! We are Open!&nbsp; 
                    </span>
                </Marquee>
            </TextMove> 
            <div style={{height:'20vh'}}>
                <a href="https://linktr.ee/makamakabcn"><img src={button_menu} className="hand-black" height='10%' width='auto'style={{position:'absolute',  bottom:'10vh', left:'20vw'}}/></a>
                <a href="https://www.google.com/maps/reserve/v/dine/c/eFY5BCNmbLU?source=pa&hl=es-ES&gei=kaQuZLChHsGQkdUP19iL0AQ&sourceurl=https://www.google.com/search?q%3Dmakamaka%2Bbarcelona%26sxsrf%3DAPwXEdcl4IavFg94LRWbhRMEnqaOQQBkWQ:1680778340598%26ei%3DZKQuZLqKJLOmkdUPpOWH2Ag%26ved%3D0ahUKEwi6zL-fi5X-AhUzU6QEHaTyAYsQ4dUDCA8%26uact%3D5%26oq%3Dmakamaka%2Bbarcelona%26gs_lcp%3DCgxnd3Mtd2l6LXNlcnAQAzIQCC4QDRCABBDHARCvARDqBDIICAAQgAQQywEyBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjICCCY6CggAEEcQ1gQQsAM6BAgjECc6BggjECcQEzoLCC4QrwEQxwEQgAQ6BQgAEIAEOg4ILhCABBDHARCvARDqBDoLCC4QgAQQxwEQrwFKBAhBGABQ_gdYzRVgrhdoAXAAeACAAaEBiAHVB5IBAzMuNpgBAKABAcgBA8ABAQ%26sclient%3Dgws-wiz-serp" >
                    <img src={button_reservas} className="hand-black" height='10%' width='auto'style={{position:'absolute',  bottom:'10vh', left:'52vw'}}/></a>
            </div>    
        </WelcomeBox>
    )
}

export default Welcome

