import { useState } from "react"
import ModalShop from "./ModalShop"
import { GridBox, GridLine, MenuImg, EnlargedModal, EnlargedBox} from "./styles/Containers"
import { Spacer } from "./styles/Containers"
import { FBText, FBTag } from "./styles/Text"


const SaladGrid = () => {

    const [show, setShow] = useState(false)
    const [img, setImg] = useState('')
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')

    const Salads = [
        {
            name: 'James Brownie',
            tagline: 'Sex Machine!',
            img:'/media/salads/james_brownie.png',
            angle:'rotate(2.23deg)'
        },
        {
            name: 'The PlayBoy Veggie',
            tagline: 'Build your six pack',
            img:'/media/salads/playboy_veggie.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Acapulco Dreamin Tortilla Chips',
            tagline: "Don't call me nachos!",
            img:'/media/salads/acapulco_dreamin.png',
            angle:'rotate(0.23deg)'
        },
        {
            name: 'Caribbean Chicken Salad',
            tagline: 'Build your six pack',
            img:'/media/salads/caribbean_chicken.png',
            angle:'rotate(0.23deg)'
        },
        {
            name: 'Classic Fries',
            tagline: 'Fries before guys!',
            img:'/media/salads/classic_fries.png',
            angle:'rotate(-2.23deg)'
        },
        {
            name: 'Falafel Sliders',
            tagline: 'Build your six pack',
            img:'/media/salads/falafel_sliders.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Hummus Threesome',
            tagline: 'Build your six pack',
            img:'/media/salads/hummus_threesome.png',
            angle:'rotate(-3.23deg)'
        },
        {
            name: 'Lilikoi Cheesecake',
            tagline: 'Cheesier Than Ever',
            img:'/media/salads/lilikoi_cheesecake.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Parmesan Garlic Fries',
            tagline: 'Fries before guys!',
            img:'/media/salads/parmesan_garlic_fries.png',
            angle:'rotate(1.123deg)'
        },
        {
            name: 'Strawberry Field Forever',
            tagline: 'Let me take you down',
            img:'/media/salads/strawberry_pie.png',
            angle:'rotate(1.56deg)'
        },
        {
            name: 'The Playboy',
            tagline: 'Build your six pack',
            img:'/media/salads/the_playboy.png',
            angle:'rotate(1.56deg)'
        }

    ]


    const enlarge = async (food, e) => {

        


        setName(food.name)
        setTag(food.tagline)
        setImg(food.img)
        setShow(true)
        console.log(e.style)
        
    }

    return (
        <GridBox>
                <GridLine>
                    <MenuImg src={window.location.origin + Salads[0].img}  alt="1" style={{transform:'rotate(2.23deg)'}} onClick={(target) => enlarge(Salads[0], target)} /> 
                    <MenuImg src={window.location.origin + Salads[1].img} style={{transform:'rotate(0.9deg)'}} onClick={() => enlarge(Salads[1])}/> 
                    <MenuImg src={window.location.origin + Salads[2].img} style={{transform:'rotate(-1.2deg)'}} onClick={() => enlarge(Salads[2])}/> 
                </GridLine>
                

                <GridLine>
                    <MenuImg src={window.location.origin + Salads[3].img} style={{transform:'rotate(3.23deg)'}} onClick={() => enlarge(Salads[3])}/> 
                    <MenuImg src={window.location.origin + Salads[4].img} style={{transform:'rotate(-1.7deg)'}} onClick={() => enlarge(Salads[4])} /> 
                    <MenuImg src={window.location.origin + Salads[5].img} style={{transform:'rotate(-0.5deg)'}} onClick={() => enlarge(Salads[5])}/> 
                </GridLine>
            


                <GridLine>
                <MenuImg src={window.location.origin + Salads[6].img}  style={{transform:'rotate(1.76deg)'}} onClick={() => enlarge(Salads[6])} /> 
                <MenuImg src={window.location.origin + Salads[7].img} style={{transform:'rotate(0.34deg)'}} onClick={() => enlarge(Salads[7])} /> 
                <MenuImg src={window.location.origin + Salads[8].img} style={{transform:'rotate(-3.12.0deg)'}} onClick={() => enlarge(Salads[8])}/>
                </GridLine>

                <GridLine>
                <MenuImg src={window.location.origin + Salads[9].img}  style={{transform:'rotate(1.76deg)'}} onClick={() => enlarge(Salads[9])} /> 
                <MenuImg src={window.location.origin + Salads[10].img} style={{transform:'rotate(0.34deg)'}} onClick={() => enlarge(Salads[10])} /> 
                </GridLine>

                {show && <ModalShop img={img} show={show} setShow={setShow} name={name} tag={tag} />}
  
        </GridBox>
    )
}

export default SaladGrid
