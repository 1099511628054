import {useEffect} from 'react'
import Marquee from "react-fast-marquee"
import {GlassMagnifier} from "react-image-magnifiers";
import { TextMove, TextMoveM, TextMoveWhite } from '../styles/Containers';
import Aos from 'aos'
import 'aos/dist/aos.css'

const Censored = () => {

    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])
    
    
    return (
        <div style={{height:'40vh', width:'100vw', position:'relative', zIndex:'0',overflow:'hidden'}}>
            <TextMoveWhite style={{top:'0', position:'absolute', top:"-10px"}}>
                <Marquee gradient={false} speed={100} >
                    <span style={{fontSize:'0.2rem', lineHeight:'0.3rem', fontFamily:'Baskerville'}}>
                        Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life!&nbsp;
                    </span>
                </Marquee>
            </TextMoveWhite>
          
            <div style={{height:'100%', width:'auto', position:'absolute', top:'-10vh'}} data-aos="fade-up">
                <GlassMagnifier magnifierSize='25%' magnifierBorderSize={1} magnifierOffsetX={70}
                    imageSrc={window.location.origin + '/media/general/mobile_naked_woman.jpg'}
                    imageAlt="Example"
                    largeImageSrc={window.location.origin + '/media/general/mobile_naked_burgers.jpg'}
                />
                {/* <img src={window.location.origin + '/media/general/naked_woman.png'} height='100%' width='auto'  /> */}
            </div>
            <TextMoveWhite style={{bottom:'10', position:'absolute', bottom:"-10px"}}>
                <Marquee gradient={false} speed={100} direction="right">
                    <span style={{fontSize:'0.2rem', lineHeight:'0.3rem', fontFamily:'Baskerville'}}>
                        Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life! Uncensor your life!&nbsp;
                    </span>
                </Marquee>
            </TextMoveWhite>
        </div>
    )

}

export default Censored
