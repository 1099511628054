import React from 'react'
import { FooterText, FooterTitle, FTBox} from './styles/Text'
import { FooterBox, FooterSocial, FooterWhere, Spacer } from './styles/Containers'
import button_group from '../images/button_group.png'
import button_reservas from '../images/button_reservas.png'

const Footer = () => {

    const clickMenu = () => {
        window.open("/media/mm_menu.pdf","_blank");
    }

    return (
        <FooterBox> 
            <div style={{margin:'auto 0', textAlign:"center"}}>
                <a href="/#home-banner"><img src={window.location.origin + '/media/logo_movil.png'} className='hand-black' style={{height:"20vh"}}/></a>
            </div> 


            <FooterWhere>
                <FooterTitle>
                    Where we are?
                </FooterTitle>
                <Spacer space='3vh'/>
                <FooterText style={{fontWeight:'bold'}}>
                    Passeig Joan de Borbó 76,<br />
                    08039 Barcelona
                </FooterText>
                <Spacer space='2vh'/>
                <FooterText>
                    <b>Lu-Ju:</b> 1pm - 12:30am<br />
                    <b>Vi-Dom:</b> 12pm - 12:30am
                </FooterText>
            </FooterWhere> 

            <FooterSocial>
                <FooterTitle>
                    Follow
                </FooterTitle>
                 <Spacer space='4vh'/>
                <FooterText>
                    <FTBox>
                    <img src={window.location.origin + '/media/general/facebook.png'} height='25vh' width='auto' style={{marginLeft:'-1vh'}}/>
                        <a target='_blank' href='https://www.facebook.com/MakamakaBcn?ref=hl' className='hand-black'>
                            <span>&nbsp;Facebook</span>
                        </a>
                    </FTBox>
                    <Spacer space='0.15vh'/>
                    
                    <FTBox>
                    <img src={window.location.origin + '/media/general/instagram.png'} height='25vh' width='auto' />
                        <a target='_blank' href='https://www.instagram.com/makamakabcn/'>
                            <span>&nbsp;Instagram</span>
                        </a>
                    </FTBox>
                    <Spacer space='0.15vh'/>
          
                    <FTBox>
                        <img src={window.location.origin + '/media/general/pinterest.png'} height='25vh' width='auto' style={{marginLeft:'-2vh'}}/>
                        <a target='_blank' href='https://www.pinterest.es/makamakabarcelona/'>
                            <span>&nbsp;Pinterest</span>
                        </a>
                    </FTBox>
                    <Spacer space='0.15vh'/>
             
                    <FTBox>
                    <img src={window.location.origin + '/media/general/tik_tok.png'} height='23vh' width='auto' style={{marginLeft:'-4vh'}}/>
                        <a target='_blank' href='https://www.tiktok.com/@makamakabcn'>
                            <span>&nbsp;Tik Tok</span>
                        </a>
                    </FTBox>
                </FooterText>

            </FooterSocial> 

            <div style={{display:'flex', flexDirection:'column', gap:'2vh', marginTop:'5vh'}}>
                <FooterTitle>
                    Contact
                </FooterTitle>
                <Spacer space='0vh'/>
                <FooterText>
                    <FTBox>
                        <img src={window.location.origin + '/media/general/mail.png'} height='22vh' width='auto' />
                        <a target='_blank' href='mailto:hola@makamaka.es'>
                            <span>&nbsp;hola@makamaka.es</span>
                        </a>
                    </FTBox>
                    <FTBox>
                            <img src={window.location.origin + '/media/general/phone.png'} height='22vh' width='auto' style={{verticalAlign:'middle', marginLeft:'-6vh'}}/>
                            &nbsp;&nbsp;
                            <a href="tel:+34932213520">
                                <span style={{marginRight:'0vh'}}>+34 932 213 520</span>
                            </a>
                    </FTBox>
                    <FTBox style={{position:'relative'}}>
                        <img src={button_group} className="hand-black" height='70vh' width='auto'style={{position:'absolute',  top:'1vh', left:'0vw'}} onClick={clickMenu} />
                        <a href="https://www.google.com/maps/reserve/v/dine/c/eFY5BCNmbLU?source=pa&hl=es-ES&gei=kaQuZLChHsGQkdUP19iL0AQ&sourceurl=https://www.google.com/search?q%3Dmakamaka%2Bbarcelona%26sxsrf%3DAPwXEdcl4IavFg94LRWbhRMEnqaOQQBkWQ:1680778340598%26ei%3DZKQuZLqKJLOmkdUPpOWH2Ag%26ved%3D0ahUKEwi6zL-fi5X-AhUzU6QEHaTyAYsQ4dUDCA8%26uact%3D5%26oq%3Dmakamaka%2Bbarcelona%26gs_lcp%3DCgxnd3Mtd2l6LXNlcnAQAzIQCC4QDRCABBDHARCvARDqBDIICAAQgAQQywEyBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjICCCY6CggAEEcQ1gQQsAM6BAgjECc6BggjECcQEzoLCC4QrwEQxwEQgAQ6BQgAEIAEOg4ILhCABBDHARCvARDqBDoLCC4QgAQQxwEQrwFKBAhBGABQ_gdYzRVgrhdoAXAAeACAAaEBiAHVB5IBAzMuNpgBAKABAcgBA8ABAQ%26sclient%3Dgws-wiz-serp" >
                            <img src={button_reservas} className="hand-black" height='60vh' width='auto'style={{position:'absolute',  top:'0.5vh', left:'10vw'}}/>
                        </a>
                    </FTBox>
                </FooterText>
            </div> 
            
        </FooterBox>
    )
}

export default Footer
