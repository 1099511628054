import React from 'react'
import useSound from 'use-sound'
import { SoundText } from './styles/Text';

const SoundWord = ({word, sound, blur}) => {

    const [play, {stop}] = useSound(window.location.origin + sound , { volume: 0.5 });

    const playSound = () => {
        play();
    }

    const stopSound = () => {
        stop();
    }

    return (
      
            <SoundText onMouseEnter={playSound} onMouseLeave={stopSound} blur={blur}>{word}</SoundText>

    )
}

export default SoundWord
