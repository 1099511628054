import { useEffect } from 'react'
import { FamilyBox, Spacer, Stickers } from '../styles/Containers'
import { WelcomeTextM, TextSM } from '../styles/Text'
import { Widget } from '@typeform/embed-react'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Family = () => {

    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])

    return (
        <FamilyBox style={{ paddingTop:'10vh'}} id='family'>   
                <WelcomeTextM>Meet Our Family!</WelcomeTextM>
                {/* <div style={{position:'relative'}}>
                    <Stickers src={window.location.origin + '/media/stickers/friends.png'} height='100px' width='auto' style={{position:'absolute', top:'-17vh', left:'-30vw'}}/>
                </div> */}
                    <TextSM style={{fontFamily:'Garamond', padding:'2vh'}} data-aos="fade-up">Una <b>gran familia</b> conlleva una <b>gran responsabilidad</b>, por eso nos preocupamos de montar un <b>buen show</b> para tenerlos <b>contentos</b> cada día.</TextSM>
                <Spacer space='1vh'/>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', gap:'2vh'}} data-aos="fade-left">
                            <img src={window.location.origin + '/media/family/1.png'} width='80%' height='auto' style={{border:'1vh solid white', transform:'rotate(-0.78deg)'}}/>
                            <img src={window.location.origin + '/media/family/2.png'} width='80%' height='auto' style={{border:'1vh solid white', transform:'rotate(1.18deg)'}}/>
                            <img src={window.location.origin + '/media/family/4.png'} width='80%' height='auto' style={{border:'1vh solid white', transform:'rotate(1.98deg)'}}/>
                            <img src={window.location.origin + '/media/family/3.png'} width='80%' height='auto' style={{border:'1vh solid white', transform:'rotate(2.18deg)'}}/>
                            <img src={window.location.origin + '/media/family/5.png'} width='80%' height='auto' style={{border:'1vh solid white', transform:'rotate(0.78deg)'}}/>
                            <img src={window.location.origin + '/media/family/6.png'} width='80%' height='auto' style={{border:'1vh solid white', transform:'rotate(0.78deg)'}}/>
                            
                </div>
                <Spacer space='5vh'/>
                {/* <WelcomeTextM style={{padding:'0 4vw'}}>We are a big family but we have space for you!</WelcomeTextM> */}
                <Spacer space='10vh' />
                {/* <div style={{height:'100vh', width:'100vw'}}>
                    <Widget id="wCdm3C6E" style={{ width:'100%', height:'100%' }} />
                </div> */}
        </FamilyBox>
    )
}

export default Family
