import "./ScreenView.css"
import React, { useEffect } from 'react';

export function ScreenView({ id, className, onClick, innerContent, buttonContent, handleScreenClick, soundSrc, showClickToContinue = true}) {
	useEffect(() => {
		if (soundSrc) {
			const audioElement = new Audio(soundSrc);
			audioElement.play();
		}
	}, [soundSrc]);

	return (
		<div id={id} className={`gau-screenview ${className}`} onClick={onClick}>
			<div className="gau-inner">
				<h2 className={id !== 'gau-screen_4' && id !== 'gau-screen_8' ? 'gau-big-text gau-h2' : 'gau-h2'}>{innerContent}</h2>
				{buttonContent && id !== 'gau-screen_16' && id !== 'gau-screen_1' && buttonContent.map(([id, buttonClass, onClickValue, buttonText]) => (
						<button
							key={id}
							className={"gau-button "+buttonClass}
							onClick={() => handleScreenClick(onClickValue)}
						>
							{buttonText}
						</button>
					))}
				{buttonContent && id === 'gau-screen_16' && buttonContent.map(([id, buttonText]) => (
					<a href="https://portal.cheerfy.com?portal_id=0059ac019fac4a3a9872896f3c847571" target="blank">
						<button
							key={id}
							className="gau-button"
						>
							{buttonText}
						</button>
					</a>
				))}
			</div>
			{buttonContent && id === 'gau-screen_1' && buttonContent.map(([id, buttonText, onClickValue]) => (
				<button
					key={id}
					className="gau-button gau-play"
					onClick={() => handleScreenClick(onClickValue)}
				>
					{buttonText}
				</button>
			))}
			{showClickToContinue && <h4 className="gau-h4 gau-blinky">Click anywhere to continue</h4>}
		</div>
	)
}