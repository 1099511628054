import {useState} from 'react'
import { MenuMobile } from '../styles/Containers'
import SoundSticker from '../SoundSticker'
import { MenuStickers } from '../styles/Containers'
import ReactPlayer from 'react-player'
import Kitchen from '../../assets/camera_mobile.mp4'

import { Navigate, NavLink } from 'react-router-dom'
import { TitleB, TitleMenu } from '../styles/Text'




const MainMenu = () => {

    const [show, setShow] = useState(false)
    const [camera, setCamera] = useState(false)

   const cameraHandler = () => {
        setShow(false)
        setCamera(true)
   }

    return (
        <div style={{position:'fixed',zIndex:'5'}}>
            <div style={{height:'15vh', width:'100vw', position:'relative'}}>
                <a href="/#home-banner"><img src={window.location.origin + '/media/logo.png'} height='40%' width='auto' style={{position:'absolute', top:'3vh', left:'3vw'}}/></a>
                <img src={window.location.origin + '/media/burger_icon.png'} height='30%' width='auto'style={{position:'absolute',  top:'3vh', right:'3vw', cursor:'pointer'}} onClick={()=> setShow(!show)} />
            </div>
           
           {/* <Animated animationIn="slideInRight" animationOut="slideOutRight" animationInDuration={2000} animationOutDuration={2000} isVisible={false}> */}
                    <MenuMobile style={{transform: (show) ? "translateX(0%)" : "translateX(100%)"}}>
                        <div style={{position : "relative", height:"100vh", width:"100vw"}}>
                        <img src={window.location.origin + '/media/general/cross_white.png'} className="hand-black" height='5%' width='auto' onClick={()=> {console.log("Close Me"); setShow(false)}} style={{position:'absolute', top:'2.5vh', right:'2.5vw'}}/>
                       <SoundSticker sound='/media/portada/menu.mp3' img={<a onClick={() => setShow(false)} href='/#menu'><MenuStickers src={window.location.origin + '/media/stickers/menu.png'} className='animate-menu' height='15%' width='auto' style={{position:'absolute', top:'50%', right:'8%', transform:'rotate(-10.78deg)'}} /></a>} />
                       
                       <SoundSticker 
                       sound='/media/portada/camera.mp3' 
                       img={<MenuStickers onClick={cameraHandler} src={window.location.origin + '/media/stickers/camera.png'} 
                       className='animate-live' height='24%' width='auto' 
                       style={{position:'absolute', bottom:'8%', left:'20%'}}/>} />
                       
                       <a href="/#Friend-Gau"><SoundSticker sound='/media/portada/friend.mp3' img={<MenuStickers src={window.location.origin + '/media/stickers/friends.png'} className='animate-live' height='15%' width='auto' style={{position:'absolute', top:'38%', left:'12%'}}/>} /></a>
                       {/* <SoundSticker sound='/media/portada/shop.mp3' img={<a onClick={() => setShow(false)} href='/#shop'><MenuStickers src={window.location.origin + '/media/stickers/shop.png'} className='animate-live' height='12%' width='auto' style={{position:'absolute', top:'10%', left:'8%', animationDelay:'0.74s'}}/></a>} /> */}
                       <SoundSticker sound='/media/portada/where.mp3' img={<a onClick={() => setShow(false)} href='/#where'><MenuStickers src={window.location.origin + '/media/stickers/where.png'} className='animate-where'  height='17%' width='auto' style={{position:'absolute', top:'12%', right:'8%'}}/></a>} />   
                       <SoundSticker sound='/media/portada/family.mp3' img={<a onClick={() => setShow(false)} href='/#family-form'><MenuStickers src={window.location.origin + '/media/stickers/family.png'} className='animate-live' height='9%' width='auto' style={{position:'absolute', top:'22%', left:'10%', transform:'rotate(10.78deg)', animationDelay:'1.4s'}}/></a>} />
                        </div>
                        
                        {/* <TitleB to="/hungry/burgers">HUNGRY</TitleB>
                        <TitleB to="/thirsty/cocktails">THIRSTY</TitleB>
                        <TitleMenu href='#shop'>SHOP</TitleMenu>
                        <TitleMenu href='#family'>JOIN THE FAM</TitleMenu>
                        <TitleB to="/friends">BE FRIENDS</TitleB> */}
                    </MenuMobile>
            {/* </Animated> */}
           { camera &&  <div style={{position:'absolute', backgroundColor:'black', height:'100vh', width:'100vw', top:'0', left:'0'}} onClick={() => setCamera(false)}> 
                <ReactPlayer style={{position:'absolute'}} url={Kitchen} height='100%' width='100%'  playing={true} />
             </div>}      
        </div>
    )
}

export default MainMenu
