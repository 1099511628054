import { Routes, Route } from "react-router";
import MediaQuery from "react-responsive";
import { AnimatePresence } from "framer-motion";
import CookieConsent from "react-cookie-consent";


// Pages Desktop
import Intro from "./pages/Desktop/Intro";
import Home from "./pages/Desktop/Home";
import Hungry from  "./pages/Desktop/Hungry"
import Thirsty from "./pages/Desktop/Thirsty";
import Tinder from "./pages/Desktop/Tinder";
import MakaFriend from "./pages/Desktop/MakaFriend";
import Benefits from "./pages/Desktop/Benefits";

// Pages Mobile
import MHome from "./pages/Mobile/Home";
import MHungry from "./pages/Mobile/Hungry";
import MThirsty from "./pages/Mobile/Thirsty";
import MTinder from "./pages/Mobile/Tinder";
import MMakaFriend from "./pages/Mobile/MakaFriend";
import MBenefits from "./pages/Mobile/Benefits";





function App() {

  return (
  <div>
    

      <MediaQuery maxDeviceWidth={500}>
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route path="/" element={<MHome />}  />
            <Route path="/hungry/:food" element={<MHungry />}  />
            <Route path="/thirsty/:drinks" element={<MThirsty />}  />
            <Route path="/friends" element={<MTinder />}  />
            <Route path="/makafriend" element={<MMakaFriend />}  />
            <Route path="/benefits" element={<MBenefits enbed={false}/>}  />
          </Routes>
        </AnimatePresence>
      </MediaQuery>

       <MediaQuery minDeviceWidth={501}>
        <AnimatePresence exitBeforeEnter>
          <Routes>
            {/* <Route path="/" element={<Intro />}  /> */}
            <Route path="/" element={<Home />}  />
            <Route path="/hungry/:food" element={<Hungry />}  />
            <Route path="/thirsty/:drinks" element={<Thirsty />}  />
            <Route path="/friends" element={<Tinder />}  />
            <Route path="/makafriend" element={<MakaFriend />}  />
            <Route path="/benefits" element={<Benefits enbed={false}/>}  />
          </Routes>
        </AnimatePresence>
      </MediaQuery>



  </div>
           
    
  );

}

export default App;
