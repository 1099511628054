import { useState } from 'react'
import { MenuBox } from '../../components/styles/Containers'
import { WelcomeText, TextS } from '../../components/styles/Text'
import { BtnFood } from '../../components/styles/Forms'
import { Spacer } from '../../components/styles/Containers'
import CocktailsGrid from '../../components/Mobile/CocktailsGrid'
import RefrescosGrid from '../../components/Mobile/RefrescosGrid'
import MainMenu from '../../components/Mobile/MainMenu'
import Footer from '../../components/Mobile/Footer'
import { Link } from 'react-router-dom'
import AnimatedPages from '../../components/AnimatedPages'
import { useEffect } from 'react'

// Moving text
import { TextMove } from '../../components/styles/Containers'
import Marquee from 'react-fast-marquee'

const Thirsty = () => {

    const [ burger, setBurger] = useState(true)
    const [ salad, setSalad] = useState(false)
    
    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    return (
        <AnimatedPages>
            <MainMenu />
            <MenuBox>
                <WelcomeText style={{fontSize:'6vh', lineHeight:'1.1'}}>Beware of Drinks!</WelcomeText>
                <Spacer space='2vh' />
                <TextS>Creamos <b>Cocktails</b> y refresco perfectos para un día de surf en <b>La Barceloneta</b> desde 2012 </TextS>
                <Spacer space='5vh' />
                <div style={{display:'flex', gap:'5vw'}}>
                    <BtnFood onClick={() => {setBurger(true); setSalad(false)}}>Cocktails</BtnFood>
                    <BtnFood onClick={() => {setBurger(false); setSalad(true)}}>Refrescos</BtnFood>
                </div>
                <Spacer space='5vh'/>
                {burger && <CocktailsGrid />}
                {salad && <RefrescosGrid />}
                <Spacer space='10vh'/>
                <TextS>Toca Bajar esta comida con algo fresquito...</TextS>
                <Spacer space='2vh'/>
                <div style={{display:'flex', gap:'5vw'}}>
                    <Link to='/hungry/burgers'><BtnFood>Burgers</BtnFood></Link>
                    <Link to='/hungry/salads'><BtnFood>Salads & Co</BtnFood></Link>
                </div>
                <TextMove style={{bottom:'0', position:'absolute', bottom:"-10px"}}>
                    <Marquee gradient={false} speed={100} direction="right" >
                        <span style={{fontSize:'0.2rem', lineHeight:'0.3rem', fontFamily:'Baskerville'}}>
                        ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi? ¡Hola Amigo! Want to say hi?&nbsp;
                        </span>
                    </Marquee>
                </TextMove>
            </MenuBox>
              
            <Footer />
        </AnimatedPages>
    )
}

export default Thirsty
