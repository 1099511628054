import { useState } from "react"
import { MenuImgM, EnlargedBox} from "../styles/Containers"
import { Spacer } from "../styles/Containers"
import { FBText, FBTag } from "../styles/Text"


const RefrescosGrid = () => {

    const [show, setShow] = useState(false)
    const [img, setImg] = useState('')
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')

    const Refrescos = [
        {
            name: "Bobby's Ice Tea",
            tagline: 'Cheesier Than Ever',
            img:'/media/refrescos/bobbys_ice_tea.png',
            angle:'rotate(2.23deg)'
        },
        {
            name: "Jackie's Ice Tea",
            tagline: 'Cheesier Than Ever',
            img:'/media/refrescos/jackies_ice_tea.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: "Lola's Lemonade",
            tagline: 'Cheesier Than Ever',
            img:'/media/refrescos/lolas_lemonade.png',
            angle:'rotate(0.23deg)'
        },
        {
            name: "Stevies Wonder Lemonade",
            tagline: 'Cheesier Than Ever',
            img:'/media/refrescos/stevies_wonder_lemonade.png',
            angle:'rotate(-2.23deg)'
        },
        {
            name: 'Banana Republic',
            tagline: 'Cheesier Than Ever',
            img:'/media/refrescos/banana_republic.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Fan of Tan',
            tagline: 'Cheesier Than Ever',
            img:'/media/refrescos/fan_of_tan.png',
            angle:'rotate(-3.23deg)'
        },
        {
            name: 'Gold Digger',
            tagline: 'Cheesier Than Ever',
            img:'/media/refrescos/gold_digger.png',
            angle:'rotate(1.23deg)'
        },
        {
            name: 'Shark Bite',
            tagline: 'Cheesier Than Ever',
            img:'/media/refrescos/shark_bite.png',
            angle:'rotate(1.123deg)'
        }
    ]


    const enlarge = async (food, e) => {

        


        setName(food.name)
        setTag(food.tagline)
        setImg(food.img)
        setShow(true)
        console.log(e.style)
        
    }

    return (
        <div style={{width:'100vw', padding:'0 5vw', position:'relative' }}>


                <MenuImgM src={window.location.origin + Refrescos[0].img}  alt="1" style={{transform:'rotate(2.23deg)'}} onClick={(target) => enlarge(Refrescos[0], target)} /> 
                <MenuImgM src={window.location.origin + Refrescos[1].img} style={{transform:'rotate(0.9deg)'}} onClick={() => enlarge(Refrescos[1])}/> 
                <MenuImgM src={window.location.origin + Refrescos[2].img} style={{transform:'rotate(-1.2deg)'}} onClick={() => enlarge(Refrescos[2])}/> 
               
                <MenuImgM src={window.location.origin + Refrescos[3].img} style={{transform:'rotate(3.23deg)'}} onClick={() => enlarge(Refrescos[3])}/> 
                <MenuImgM src={window.location.origin + Refrescos[4].img} style={{transform:'rotate(-1.7deg)'}} onClick={() => enlarge(Refrescos[4])} /> 
                <MenuImgM src={window.location.origin + Refrescos[5].img} style={{transform:'rotate(-0.5deg)'}} onClick={() => enlarge(Refrescos[5])}/> 
     
                <MenuImgM src={window.location.origin + Refrescos[6].img}  style={{transform:'rotate(1.76deg)'}} onClick={() => enlarge(Refrescos[6])} /> 
                <MenuImgM src={window.location.origin + Refrescos[7].img} style={{transform:'rotate(0.34deg)'}} onClick={() => enlarge(Refrescos[7])} /> 


                {/* {show && <div style={{backgroundColor:'rgba(0,0,0,0.1)', height:'100vh', width:'100vw', position:'fixed', zIndex:'2', top:'0%', left:'0%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}} onClick={() => setShow(false)}>

                    <EnlargedBox src={img}>
                        <span style={{position:'absolute',  top:'2vh', right:'1vw', fontSize:'5vh', fontWeight:'bold', cursor:'pointer', color:'white'}} onClick={()=> setShow(!show)}>X</span>
                        <div>
                            <FBText>{name}</FBText>
                            <br/>
                            <Spacer space='2vh'/>
                            <FBTag>{tag}</FBTag>                             
                        </div>
                    </EnlargedBox>


                </div>} */}
  
        </div>
    )
}

export default RefrescosGrid
