import { useState, useRef } from "react"
import AnimatedPages from "../../components/AnimatedPages"
import { Spacer, CarnetDiv, MakaCarnet, CarnetImage, CenterDiv } from "../../components/styles/Containers"
import { FText, FInput, FSubmit, TextS, TinderInfo } from "../../components/styles/Text"
import Makamaka_C from '../../images/Character/Makamaka_C.png'
import Arnold_C from '../../images/Character/Arnold_C.png'
import Pamela_C from '../../images/Character/Pamela_C.png'
import Makamaka from '../../images/MakaFriend/MM_friend_card-Topless.png'
import Arnold from '../../images/MakaFriend/MM_friend_card-Arnold.png'
import Pamela from '../../images/MakaFriend/MM_friend_card-Pamela.png'
import * as htmlToImage from 'html-to-image';
import bwipjs from 'bwip-js';
import { GrClose } from "react-icons/gr"
import Benefits from "./Benefits"

const MakaFriend = () => {

    const FriendCard = useRef(null);
    const [Benefit, setBenefit] = useState(false)

 
    const [first, setFirst] = useState(true)
    const [last, setLast] = useState(false)
    const [dob, setDob] = useState(false)
    const [hero, setHero] = useState(false)
    const [card, setCard] = useState(true)
    const [carnet, setCarnet] = useState(Makamaka)

    const [firstV, setFirstV ] = useState('')
    const [lastV, setLastV ] = useState('')
    const [dateV, setDateV ] = useState('')



    const GenerateCard = () => {

        try {
            // The return value is the canvas element
            let canvas = bwipjs.toCanvas('mycanvas', {
                      bcid:        'code128',       // Barcode type
                      text:        firstV+lastV+dateV,    // Text to encode
                      scale:       3,               // 3x scaling factor
                      height:      3,              // Bar height, in millimeters
                      width:       1, 
                      includetext: false,            // Show human-readable text
                      textxalign:  'center',        // Always good to set this
                  });
          } catch (e) {
              // `e` may be a string or Error object
          }
    }

    const downloadCard = async () => {

        GenerateCard();

        const dataUrl = await htmlToImage.toPng(FriendCard.current);
     
        // download image
        const link = document.createElement('a');
        link.download = "FriendCard.png";
        link.href = dataUrl;
        link.click();
      }

    return (
        <AnimatedPages>
            <div style={{display:'flex', textAlign:'center', justifyContent:'center', alignItems:'center', padding:'10vh 20vh'}}>
            {
            Benefit &&
            <div style={{position:"absolute", zIndex:10, backgroundColor:"#f4eee0", width:"80vw", height:"80vh", border:"1px solid black", margin:"10vh 10vw", top:'0vh'}}>
              <GrClose onClick={() => setBenefit(false)} style={{zIndex:10, position:"absolute", top:"1vh", right:"1vh", fontSize:"5vh", cursor:"pointer"}}/>
              <Benefits enbed={true}/>
            </div>
          }
          <TinderInfo onClick={() => setBenefit(true)} style={{color:"pink"}}>
            <a style={{position:'absolute', right:"1vw", top:"7vh", rotate:"10deg", fontSize:"5vh"}}>Benefits you said ?</a>
          </TinderInfo>
                <div>
                    <form >
                        <CenterDiv style={{height:"100vh", width:"100vw"}}>
                        {first && <div>
                            <FText>Your First Name?</FText>
                            <Spacer space='20vh'/>
                            <FInput type='text' name='name' value={firstV} onChange={(e)=> setFirstV(e.target.value)} />
                            <Spacer space='10vh'/>
                            <FSubmit onClick={() => (setFirst(false), setLast(true))}>SUBMIT</FSubmit>
                            </div>}
                        {last && <div>
                            <FText>Your Last Name?</FText>
                            <Spacer space='20vh'/>
                            <FInput type='text' name='name' value={lastV} onChange={(e)=> setLastV(e.target.value)} />
                            <Spacer space='10vh'/>
                            <FSubmit onClick={() => (setLast(false), setDob(true))}>SUBMIT</FSubmit>
                        </div>}
                        {dob && <div>
                            <FText>Your Date of Birth?</FText>
                            <Spacer space='20vh'/>
                            <FInput type='text' name='name' placeholder='dd-mm-yyyy' value={dateV} onChange={(e)=> setDateV(e.target.value)}/>
                            <Spacer space='10vh'/>
                            <FSubmit onClick={() => (setDob(false), setHero(true))}>SUBMIT</FSubmit>
                        </div>}

                        {hero && <div>
                            <FText style={{marginLeft:'-5vw', lineHeight:'1.1'}}>Pick Your Character</FText>
                            <Spacer space='20vh'/>
                            <div style={{display:'flex', gap:'2vw'}}>
                                <div className='tinder-button'>
                                    <input type='radio' name='friend-card' value='pamela' id='pamela' onChange={(e)=> setCarnet(Pamela)}/>
                                    <label htmlFor='pamela'> 
                                        <CarnetImage src={Pamela_C} />
                                    </label>
                                </div>
                                <div className='tinder-button'>
                                    <input type='radio' name='friend-card' value='arnold' id='arnold' onChange={(e)=> setCarnet(Arnold)}/>
                                    <label htmlFor='arnold'> 
                                        <CarnetImage src={Arnold_C}/>
                                    </label>
                                </div>
                                <div className='tinder-button'>
                                    <input type='radio' name='friend-card' value='maka' id='maka' onChange={(e)=> setCarnet(Makamaka)}/>
                                    <label htmlFor='maka'> 
                                        <CarnetImage src={Makamaka_C}/>
                                    </label>
                                </div>  
                            </div>
                            <Spacer space='5vh'/>
                            


                            <CarnetDiv>
                            

                                <MakaCarnet id="FriendCard" ref={FriendCard} style={{display:"flex", flexDirection:"column", position:"relative"}}>
                                    
                                    <div style={{display:"flex", flexDirection:"row", width:"100%", height:"auto", margin:"auto", textAlign:"center", position:"relative", zIndex:"-1"}}>
                                        <p style={{position:"absolute", fontSize:"1.3vw", left:"51%", top:"15%", color:"black"}}>{firstV}</p>
                                        <p style={{position:"absolute", fontSize:"1.3vw", left:"51%", top:"28%", color:"black"}}>{lastV}</p>
                                        <p style={{position:"absolute", fontSize:"1vw", left:"51%", top:"41%", color:"black", fontWeight:"lighter"}}>{dateV}</p>
                                       
                                        <canvas style={{position:'absolute', top:'60%', left:'51%', width:'43%', height:'12%', overflow:'hidden'}} id="mycanvas"></canvas>
                                        
                                        <img src={carnet} style={{width:"100%"}}></img>
                                    </div>
                                    
                                </MakaCarnet>

                                <FSubmit type='button' onClick={downloadCard} style={{height:'10vh', marginTop:"2vh"}}>Download</FSubmit>

                                                                
                            </CarnetDiv>

                            

                            {/* <FSubmit type='button' onClick={GenerateCard}>SUBMIT</FSubmit> */}

                           
                            
                        </div>}
                        </CenterDiv>


                    </form>
                </div>
            </div>
        </AnimatedPages>
    )
}

export default MakaFriend
