import styled from "styled-components";
import { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import hand from "./hand_small.png"
// import handBlack from "../../hand_black.png"
import handBlack from "../../hand_black.png"
import { device } from "./Device";


export const WelcomeText= styled.span`
 font-size: clamp(0.5rem, 5vw + 0.2rem, 1rem);
 font-family: ITC_Bold;
 text-transform: uppercase;
`

export const SoundText = styled.span`
 
  font-size: clamp(0.5rem, 7vw + 0.2rem, 1rem);
   /* font-size: 14vh; */
  font-family: ITC_Bold;
  text-transform: uppercase;
  line-height: 1.1;
  opacity: ${ props => props.blur ? 0.1 : 1};
  color :white;
    -webkit-text-stroke-width: 2.5px;
    -webkit-text-stroke-color: black;

  :hover{
    cursor: url(${handBlack}), auto;
    /* filter:invert(50%) */
    color:white !important;
    

  }
`

export const TitleB = styled(NavLink)`
 font-size: 6vh;
 font-family: ITC_Semi;
 line-height: 1.1;

 :hover {
   cursor: url(${handBlack}), auto;
   color:white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
 }
`

export const TitleMenu = styled.a`
 font-size: 6vh;
 font-family: ITC_Semi;
 line-height: 1.1;

 :hover {
    cursor: url(${handBlack}), auto;
   color:white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
 }
`

export const TextS = styled.span`
  font-size: clamp(0.1rem, 2vw + 0.12rem, 0.25rem);
  font-family: Garamond;
  line-height: 1.2;
`

export const FooterTitle = styled.span`
 font-size: 4vh;
 text-transform: uppercase;

 @media ${device.mobileS} {
  text-align: center;
  width: 100%;
  /* padding-top: 9vh; */
 }

 @media ${device.tablet} {
  text-align: center;
  width: 100%;
  padding-top: 9vh;
 }

 @media ${device.laptop} {
  padding-top: 0vh;
  text-align: left;
  width: 100%;
 }
 
`

export const FooterText = styled.span`
 font-size: 3vh;
 font-family: Garamond;
 line-height: 1.4;
`

export const FTBox = styled.div`
  display:flex; 
  justify-content:center; 
  align-items:center; 
  height:5vh;

  a:hover {
    cursor: url(${handBlack}), auto !important;
  }

  img {
    vertical-align: top;
  }

  span {
    font-family: Garamond;
    font-size: 3.5vh;
    font-weight: bold;
  }

`

// Mobile

export const WelcomeTextM = styled.span`
 font-size: 6vh;
 line-height: 1.1;
 font-family: ITC_Bold;
 text-transform: uppercase;
`

export const SoundTextM = styled.span`
  font-size: 6vh;
  line-height: 1.1;
  font-family: ITC_Bold;
  text-transform: uppercase;
  color:white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

  /* :hover{
    filter:invert(50%)
    color:black;
  } */

`

export const TextSM = styled.span`
 font-size: 2.8vh;
 line-height: 1.3;
 /* line-height:1.1; */
 font-family: Garamond;
 
`

// FB-Page 

export const FBText = styled.span`
    font-size:15vh;
    text-transform: uppercase;
    color: #FF9EA0;
    font-family:ITC_Bold;
    transform: scale(1,2) !important;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    line-height: 1.1;
    
`

export const FBTag = styled.span`
    font-size:5vh; 
    color:white;
    text-decoration: underline;
    text-transform: uppercase; 
`






// Tinder Game
const glow = keyframes`

    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
        }

    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }

`

export const gifBottom = styled.span`
  font-size:10vh;
  color: #fff;
  /* text-align: center;
  animation-name: ${glow};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate; */
`

const popup = keyframes`

      0%   { transform: scale(1,1) }
      10%  { transform: scale(1.1,1.1) }
      30%  { transform: scale(.9,.9) }
      50%  { transform: scale(1,1) }
      57%  { transform: scale(1,1) }
      64%  { transform: scale(1,1) }
      100% { transform: scale(1,1) }

` 

export const TinderInfo = styled.span`
  font-size: 6vh;
  line-height: 6vh;
  justify-content: center;
  display: flex;
  color: #fff;
  -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  animation-name: ${popup};
  animation-duration: 800ms;
`


export const GifText = styled.div`

  position:fixed; 
  z-index:1; 
  height:100vh; 
  width:100vw; 
  display:flex; 
  align-items:center;
  justify-content:center;

  span{
    font-family: ITC_Bold;
    /* letter-spacing: 1px; */
    font-size:10vh;
    line-height: 1.1;
    text-align:center; 
    /* font-variant-caps:all-small-caps; */
    color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }
  

`

const animate = keyframes`
 
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
    }

`

export const GifBlink = styled.div`
    position:fixed;
    height:100vh; 
    width:100vw; 
    display:flex; 
    align-items:end; 
    justify-content:center; 
    bottom:5vh;

    span{
      animation: ${animate} 1.5s linear infinite;
      color:white;
      font-size:3vh;
      font-weight:bold;
      -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    }
  
`


export const FText = styled.span`
    font-size:7vw;
    position: absolute;
    left:25%;
    color: #FF9EA0;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    transform: rotate(2.38deg);
    transform: scaleY(1.8);
`

export const FInput = styled.input`
  background-color: transparent;
  font-size: 10vh;
  border:none;
  border-bottom: 2px solid black;
  text-align: center;
  width:35vw;

  ::placeholder{
    text-align: center;
  }

  :focus{
    outline: none;
  }
`

export const FSubmit = styled.button`
  border:none;
  font-size: 5vh;
  padding:2vh 5vh;
  background-color: black;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
  
`

// Mobile Tinder

export const TinderInfoM = styled.span`
  font-size: 3vh;
  line-height: 3vh;
  justify-content: center;
  display: flex;
  color: #fff;
  -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  animation-name: ${popup};
  animation-duration: 800ms;
`



export const GifTextM = styled.div`

  position:fixed; 
  z-index:1; 
  height:100vh; 
  width:100vw; 
  display:flex; 
  align-items:center;
  justify-content:center;

  span{
    font-family: ITC_Bold;
    /* letter-spacing: 1px; */
    font-size:6vh;
    line-height: 1.1;
    text-align:center; 
    /* font-variant-caps:all-small-caps; */
    color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }
  

`
export const FTextM = styled.span`
    font-size:15vw;
    line-height: 1.1;
    color: #FF9EA0;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    transform: rotate(2.38deg);
    transform: scaleY(1.8);
`
export const FInputM = styled.input`
  background-color: transparent;
  font-size: 10vw;
  border:none;
  border-bottom: 2px solid black;
  width:60%;
  text-align: center;

  :focus{
    outline: none;
  }
`

export const FSubmitM = styled.button`
  border:none;
  font-size: 3vh;
  padding:2vh 5vh;
  background-color: black;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  
`
