import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
import { SliderDivM } from '../styles/Containers';
import slide1 from '../../images/slider/mobile/01.jpg'
import slide2 from '../../images/slider/mobile/02.jpg'
import slide3 from '../../images/slider/mobile/03.jpg'
import slide4 from '../../images/slider/mobile/04.jpg'
import slide5 from '../../images/slider/mobile/05.jpg'
import slide6 from '../../images/slider/mobile/06.jpg'
import slide7 from '../../images/slider/mobile/07.jpg'
import slide8 from '../../images/slider/mobile/08.jpg'



const MakaSlider = () => {


    return (
        <div style={{height:'100vh', width:'100vw'}}>
            <Swiper
            slidesPerView={1}
            loop={true}
            // pagination={{clickable: true}}
            modules={[Pagination,Autoplay]}
            autoplay={{delay: 1000}}>
                <SwiperSlide>
                    <SliderDivM src={slide1}>
                    </SliderDivM>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDivM src={slide2}>
                    </SliderDivM>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDivM src={slide3}>
                    </SliderDivM>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDivM src={slide4}>
                    </SliderDivM>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDivM src={slide5}>
                    </SliderDivM>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDivM src={slide6}>
                    </SliderDivM>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDivM src={slide7}>
                    </SliderDivM>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDivM src={slide8}>
                    </SliderDivM>
                </SwiperSlide>
            </Swiper>
      </div>
    )
}

export default MakaSlider
