import { useState } from 'react';
import {Loading} from '../components/ClubHaus/Loading/Loading'
import {Exit} from '../components/ClubHaus/Exit/Exit'
import {ScreenView} from '../components/ClubHaus/ScreenView/ScreenView'
import beach from "../assets/sound/playa.mp3"
import wrong from "../assets/sound/wrong.mp3"
import correct from "../assets/sound/correct.mp3"
import paparazzi from "../assets/sound/paparazzi.mp3"
import win from "../assets/sound/win.mp3"

const FriendGau = () => {
    const [currentScreen, setCurrentScreen] = useState(1);

    const handleScreenClick = (screenNumber) => {
        setCurrentScreen(screenNumber);
    };

    const renderScreen = () => {
        switch (currentScreen) {
            case 1:
                return (
                    <ScreenView
                        id="gau-screen_1"
                        showClickToContinue={false}
                        handleScreenClick={handleScreenClick}
                        buttonContent={[
                            ['start',"Let's Play", 2]
                        ]}
                    />
                );
            case 2:
                return (
                    <ScreenView
                        id="gau-screen_2"
                        className="regrets"
                        onClick={() => handleScreenClick(3)}
                        innerContent="Choose Wisely"
                    />
                );
            case 3:
                return (
                    <ScreenView
                        id="gau-screen_3"
                        onClick={() => handleScreenClick(4)}
                        innerContent="Hey honey bunny! Our bowls are fucking healthy but..."
                        soundSrc={beach}
                    />
                );
            case 4 :
                return(
                    <ScreenView
                    id="gau-screen_4"
                    innerContent="What is the record for burgers eaten in one minute?"
                    showClickToContinue={false}
                    buttonContent={[
                        ['lose1','wrong', '4b', '3'],
                        ['win','correct', '4a', '5'],
                        ['lose2','wrong', '4b', '108'],
                    ]}
                    handleScreenClick={handleScreenClick}
                    />
                )
            case '4a':
                return (
                    <ScreenView
                        id="gau-screen_5"
                        onClick={() => handleScreenClick(5)}
                        innerContent="Fuck yes!"
                        soundSrc={correct}
                    />
                );
            case '4b':
                return (
                    <ScreenView
                        id="gau-screen_6"
                        buttonContent={[
                            ['try_again','', 2, 'TRY AGAIN?']
                        ]}
                        handleScreenClick={handleScreenClick}
                        showClickToContinue={false}
                        soundSrc={wrong}
                    />
                );
            case 5:
                return (
                    <ScreenView
                        id="gau-screen_7"
                        onClick={() => handleScreenClick(6)}
                        innerContent="Look who's coming through the Makamaka door..."
                        soundSrc={paparazzi}
                    />
                );
            case 6:
            return(
                <ScreenView
                    id="gau-screen_8"
                    innerContent="Which celebrities have frequently visited Makamaka to enjoy our Bad Ass Burger?"
                    showClickToContinue={false}
                    buttonContent={[
                        ['lose1','wrong', '6b', 'BILL MURRAY'],
                        ['lose2','wrong', '6b', 'SAMUEL L. JACKSON'],
                        ['win','correct', '6a', 'SHAKIRA'],
                    ]}
                    handleScreenClick={handleScreenClick}
                />
            );
            case '6b':
                return (
                    <ScreenView
                        id="gau-screen_10"
                        innerContent="Go Home, We Don't Want you!"
                        buttonContent={[
                            ['try_again','', 2, 'TRY AGAIN?']
                        ]}
                        handleScreenClick={handleScreenClick}
                        showClickToContinue={false}
                        soundSrc={wrong}
                    />
                );
            case '6a':
                return (
                    <ScreenView
                        id="gau-screen_9"
                        onClick={() => handleScreenClick(7)}
                        innerContent="¡ÉXITO!"
                        soundSrc={correct}
                    />
                );
            case 7:
                return (
                    <ScreenView
                        id="gau-screen_16"
                        innerContent="You are close to be a Makamaka friend!"
                        buttonContent={[
                            ['signup','Sign Up Here']
                        ]}
                        showClickToContinue={false}
                        soundSrc={win}
                    />
                );
            default:
                return null;
        }
    };
  return (
    <div id="Friend-Gau">
        <div className={"gau-screen_container"}>
            {currentScreen !== 1 && (
                <>
                    <Loading />
                    <Exit />
                </>
            )}
            {renderScreen()}
        </div>
    </div>
  );
}

export default FriendGau
