import { useState, useRef } from "react"
import AnimatedPages from "../../components/AnimatedPages"
import { MakaFormM, CarnetBoxM, CarnetImageM, MakaCarnetM, Spacer, CarnetDiv, MakaFormHeroM } from "../../components/styles/Containers"
import { FTextM, FInputM, FSubmitM, TextSM, FSubmit, TinderInfoM } from "../../components/styles/Text"
import Makamaka from '../../images/MakaFriend/MM_friend_card-Topless.png'
import Arnold from '../../images/MakaFriend/MM_friend_card-Arnold.png'
import Pamela from '../../images/MakaFriend/MM_friend_card-Pamela.png'
import Makamaka_C from '../../images/Character/Makamaka_C.png'
import Arnold_C from '../../images/Character/Arnold_C.png'
import Pamela_C from '../../images/Character/Pamela_C.png'
import * as htmlToImage from 'html-to-image';
import bwipjs from 'bwip-js';
import { GrClose } from "react-icons/gr"
import Benefits from "./Benefits"

const MakaFriend = () => {

  const [Benefit, setBenefit] = useState(false)
  const FriendCard = useRef(null);
 
    const [first, setFirst] = useState(true)
    const [last, setLast] = useState(false)
    const [dob, setDob] = useState(false)
    const [hero, setHero] = useState(false)
    const [card, setCard] = useState(true)
    const [carnet, setCarnet] = useState(Makamaka)

    const [firstV, setFirstV ] = useState('')
    const [lastV, setLastV ] = useState('')
    const [dateV, setDateV ] = useState('')
    const [heroV, setHeroV] = useState('')


    const GenerateCard = () => {

        try {
            // The return value is the canvas element
            let canvas = bwipjs.toCanvas('mycanvas', {
                      bcid:        'code128',       // Barcode type
                      text:        firstV+lastV+dateV,    // Text to encode
                      scale:       3,               // 3x scaling factor
                      height:      3,              // Bar height, in millimeters
                      width:       20, 
                      includetext: false,            // Show human-readable text
                      textxalign:  'center',        // Always good to set this
                  });
          } catch (e) {
              // `e` may be a string or Error object
          }
    }

    const downloadCard = async () => {

        GenerateCard()

        const dataUrl = await htmlToImage.toPng(FriendCard.current);
     
        // download image
        const link = document.createElement('a');
        link.download = "FriendCard.png";
        link.href = dataUrl;
        link.click();
      }

    return (
        <AnimatedPages>
            {!hero && <MakaFormM>
                    {
                Benefit &&
                <div style={{position:"absolute", zIndex:10, backgroundColor:"#f4eee0", width:"90vw", height:"90vh", border:"1px solid black", margin:"5vh 5vw"}}>
                    <GrClose onClick={() => setBenefit(false)} style={{zIndex:10, position:"absolute", top:"1vh", right:"1vh", fontSize:"5vh", cursor:"pointer"}}/>
                    <Benefits enbed={true}/>
                </div>
            }
            
            <TinderInfoM style={{color:"pink"}}>
                <a onClick={() => setBenefit(true)} style={{position:'absolute', right:"1vw", top:"7vh", rotate:"8deg", fontSize:"3.5vh", zIndex:2}}>Benefits you said ?</a>
            </TinderInfoM>
                        
                        {first && <div>
                                <FTextM>Your First Name?</FTextM>
                                <Spacer space='10vh'/>
                                <FInputM type='text' name='name' value={firstV} onChange={(e)=> setFirstV(e.target.value)} />
                                <Spacer space='10vh'/>
                                <FSubmitM onClick={() => (setFirst(false), setLast(true))}>SUBMIT</FSubmitM>
                            </div>}
                        {last && <div>
                            <FTextM>Your Last Name?</FTextM>
                            <Spacer space='20vh'/>
                            <FInputM type='text' name='name' value={lastV} onChange={(e)=> setLastV(e.target.value)} />
                            <Spacer space='10vh'/>
                            <FSubmitM onClick={() => (setLast(false), setDob(true))}>SUBMIT</FSubmitM>
                        </div>}
                        {dob && <div>
                            <FTextM>Your Date of Birth?</FTextM>
                            <Spacer space='20vh'/>
                            <FInputM type='text' name='name' placeholder='dd-mm-yyyy' value={dateV} onChange={(e)=> setDateV(e.target.value)}/>
                            <Spacer space='10vh'/>
                            <FSubmitM onClick={() => (setDob(false), setHero(true))}>SUBMIT</FSubmitM>
                        </div>}
                    </MakaFormM>}
                        
                    <MakaFormHeroM>
                        {hero && <div style={{marginTop:'0vh'}}>
                            <FTextM>Pick Your Character</FTextM>
                            <Spacer space='2vh'/>
                            <CarnetBoxM>
                                <div className='tinder-button'>
                                    <input type='radio' name='friend-card' value='pamela' id='pamela' onChange={(e)=> setCarnet(Pamela)}/>
                                    <label htmlFor='pamela'> 
                                        <CarnetImageM src={Pamela_C} />
                                    </label>
                                </div>
                                <div className='tinder-button'>
                                    <input type='radio' name='friend-card' value='arnold' id='arnold' onChange={(e)=> setCarnet(Arnold)}/>
                                    <label htmlFor='arnold'> 
                                        <CarnetImageM src={Arnold_C} />
                                    </label>
                                </div>
                                <div className='tinder-button'>
                                    <input type='radio' name='friend-card' value='maka' id='maka' onChange={(e)=> setCarnet(Makamaka)}/>
                                    <label htmlFor='maka'> 
                                        <CarnetImageM src={Makamaka_C} />
                                    </label>
                                </div>  
                            </CarnetBoxM>

                            {card && 
                                <CarnetDiv>
                                
                                    <div id="FriendCard" ref={FriendCard}>
                                    <MakaCarnetM style={{display:"flex", flexDirection:"column", position:"relative"}}>
                                        
                                        <div style={{display:"flex", flexDirection:"row", width:"100%", height:"auto", margin:"auto", textAlign:"center", position:"relative", zIndex:"-1"}}>
                                            <p style={{position:"absolute", fontSize:"3.8vw", left:"51%", top:"15%", color:"black"}}>{firstV}</p>
                                            <p style={{position:"absolute", fontSize:"3.8vw", left:"51%", top:"28%", color:"black"}}>{lastV}</p>
                                            <p style={{position:"absolute", fontSize:"2.9vw", left:"51%", top:"41%", color:"black"}}>{dateV}</p>
                                        
                                            <canvas style={{position:'absolute', top:'60%', left:'51%', width:'43%', height:'12%', overflow:'hidden'}} id="mycanvas"></canvas>
                                            
                                            <img src={carnet} style={{width:"100%"}}></img>
                                        </div>
                                        
                                    </MakaCarnetM>
                                    </div>

                                    <FSubmit type='button' onClick={downloadCard} style={{height:'10vh', marginTop:"2vh"}}>Download</FSubmit>

                                                                    
                                </CarnetDiv>
                            }
                           
                            </div>}
                        </MakaFormHeroM>
                        
        </AnimatedPages>
    )
}

export default MakaFriend
