import React from 'react'
import { CenterDiv, Spacer } from '../../components/styles/Containers'
import { BtnFood, BtnFoodM } from '../../components/styles/Forms'
import { FText, FTextM, TextS, TextSM, TinderInfo, TinderInfoM } from '../../components/styles/Text'

const Benefits = ({enbed}) => {
  // console.log(enbed)
  return (
    <CenterDiv style={enbed ? {height:"100%", width:"100%"} : {height:"100vh", width:"100vw"}}>
        {
          !enbed && <a href='/#home-banner'><img src={window.location.origin + '/media/logo.png'} height='50vh' width='auto' style={{ position:'absolute', top:'1.5vh', left:'1.5vh'}}/></a>
        }
        <div style={{width:"80vw", textAlign:"center", fontSize:"50%"}}>
            <FTextM style={{color:"pink", textAlign:"center"}}>Benefits you said ??</FTextM>
            <Spacer space='5vh'/>
            <TinderInfoM style={{textAlign:"center"}}>1. Magic Mondays @Makamaka</TinderInfoM>
            <TextSM>
                Cada mes tenemos una nueva oferta para los amigos durante los lunes! <br /> mandaremos un mailing para avisarles de la oferta + post<br/>
                    Primer mes, con cada comida o cena para dos os regalamos patatas (2 burgers o bowls = unas fries a compartir gratis). <br/><br/>
            </TextSM>
            <TinderInfoM style={{textAlign:"center"}}>2. Maka shot on the house</TinderInfoM>
            <TextSM>For amigos only, with lunch or dinner, 7 days a week. <br/><br/></TextSM>
            <TinderInfoM style={{textAlign:"center"}}>3. Birthday Madness</TinderInfoM>
            <TextSM>All YOU can eat & drink (only for the bday boy/girl)</TextSM>
            
            {
              !enbed && <div style={{width:"100%", textAlign:"center", paddingTop:"3vh"}}>
              <BtnFoodM>
                <a href='/friends'>Let's play the game !</a>
              </BtnFoodM>
            </div>
            }
        </div>
    </CenterDiv>
  )
}

export default Benefits