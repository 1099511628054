import {useRef} from 'react'
import { useState, useEffect } from 'react'
import Intro from '../../components/Intro'
import Banner from '../../components/Banner'
import Welcome from '../../components/Welcome'
import WhereBlock from '../../components/WhereBlock'
import FBSelector from '../../components/FBSelector'
import Censored from '../../components/Censored'
import MakaSlider from '../../components/MakaSlider'
import Shop from '../../components/Shop'
import MainMenuHome from '../../components/MainMenuHome'
import Playlist from '../../components/Playlist'
import Family from '../../components/Family'
import LocationMap from '../../components/LocationMap'
import Footer from '../../components/Footer'
import AnimatedPages from '../../components/AnimatedPages'
import { Widget } from '@typeform/embed-react'
import FriendGau from '../../components/FriendGau'





const Home = () => {

    const [show, setShow] = useState(true)

    const IntroSession = () => {
        setShow(false)
        window.sessionStorage.setItem("intro", false)
    }

    useEffect (() => {
        let x = window.sessionStorage.getItem("intro")
        
        if ( x === 'false' ){
            setShow(false)
        }   
    },[])

    return (
        <AnimatedPages>
           {/* {show && <CookiePreload onClick={() => setShow(false)}/>} */}
           { show && <Intro onClick={IntroSession} />}
            <MainMenuHome />
            <Welcome />
            <MakaSlider />
            <FBSelector />
            <FriendGau />
            {/* <div style={{height:'100vh', width:'100vw', marginTop:'-3vh'}}>
                <Widget id="lQ2nHtp7" style={{ width:'100%', height:'100%' }} />
            </div> */}
            <Playlist />
            <Family />
            <Censored />
            <div style={{height:'100vh', width:'100vw', marginTop:'-3vh'}} id='family-form'>
                <Widget id="Pw3wb1Cf" style={{ width:'100%', height:'100%' }} />
            </div>
            <LocationMap />
            <Footer />
        </AnimatedPages>


    )
}

export default Home
