import {useState, useEffect} from 'react'
import { MainMenuBox, MenuItemBox } from './styles/Containers'
import SoundSticker from './SoundSticker'
import { MenuStickers } from './styles/Containers'
import { TitleB, TitleMenu } from './styles/Text'
import { NavLink } from 'react-router-dom'
import logo_white from '../images/logo_white.png'
import logo_black from '../images/logo.png'
import burger_white from '../images/burger_white.png'
import burger_black from '../images/burger_icon.png'
import button_menu from '../images/button_menu.png'
import button_reservas from '../images/button_reservas.png'
import ReactPlayer from 'react-player'
import Kitchen from '../assets/camera_mobile.mp4'



const MainMenu = () => {

    const [scrollDir, setScrollDir] = useState("scrolling down");
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const [white, setWhite] = useState(true)
    const [logo, setLogo] = useState(logo_white)
    const [burger, setBurger] = useState(burger_white)
    const [camera, setCamera] = useState(false)

    const clickMenu = () => {
        window.open("/media/mm_menu.pdf","_blank");
    }

    const cameraHandler = () => {
        setShow(false)
        setCamera(true)
   }

    useEffect(() => {   


        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;


            if( scrollY >= 0.80 * window.innerHeight){
            setWhite(false)
            setLogo(logo_black)
            setBurger(burger_black)
            } else {
                setWhite(true)
                setLogo(logo_white)
                setBurger(burger_white)
            }
        };


        const onScroll = () => {
            window.requestAnimationFrame(updateScrollDir);
        };


    window.addEventListener("scroll", onScroll);

    }, [scrollDir]);


    return (
        <div style={{position:'fixed',zIndex:'35'}}>
            <div style={{height:'15vh', width:'100vw', position:'relative'}}>
                <a href="/#home-banner">
                    <img src={logo_black} className="hand-black" height='50%' width='auto' style={{position:'absolute', top:'2vh', left:'2vw'}}/> 
                </a>
                <a href="http://makamakamenu.es/menu/" target='_blank'><img src={button_menu} className="hand-black" height='60%' width='auto'style={{position:'absolute',  top:'1vh', left:'16vw'}}/></a>
                <a href="https://www.google.com/maps/reserve/v/dine/c/eFY5BCNmbLU?source=pa&hl=es-ES&gei=kaQuZLChHsGQkdUP19iL0AQ&sourceurl=https://www.google.com/search?q%3Dmakamaka%2Bbarcelona%26sxsrf%3DAPwXEdcl4IavFg94LRWbhRMEnqaOQQBkWQ:1680778340598%26ei%3DZKQuZLqKJLOmkdUPpOWH2Ag%26ved%3D0ahUKEwi6zL-fi5X-AhUzU6QEHaTyAYsQ4dUDCA8%26uact%3D5%26oq%3Dmakamaka%2Bbarcelona%26gs_lcp%3DCgxnd3Mtd2l6LXNlcnAQAzIQCC4QDRCABBDHARCvARDqBDIICAAQgAQQywEyBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjICCCY6CggAEEcQ1gQQsAM6BAgjECc6BggjECcQEzoLCC4QrwEQxwEQgAQ6BQgAEIAEOg4ILhCABBDHARCvARDqBDoLCC4QgAQQxwEQrwFKBAhBGABQ_gdYzRVgrhdoAXAAeACAAaEBiAHVB5IBAzMuNpgBAKABAcgBA8ABAQ%26sclient%3Dgws-wiz-serp" ><img src={button_reservas} className="hand-black" height='60%' width='auto'style={{position:'absolute',  top:'1vh', left:'25vw'}}/></a>
               
                    <img src={burger_black} className="hand-black" height='40%' width='auto'style={{position:'absolute',  top:'2vh', right:'1.5vw'}} onClick={ ()=> setShow(true)} />
            </div>
           
           
           <MainMenuBox style={{transform: (show) ? "translateX(0%)" : "translateX(100%)"}}>
                    <img src={window.location.origin + '/media/general/cross_white.png'} className="hand-black" height='5%' width='auto' onClick={()=> {console.log("Close Me"); setShow(false)}} style={{position:'absolute', top:'2.5vh', right:'1.5vw'}}/>
                    
                        <MenuItemBox onClick={()=> setShow(false)}>
                            <SoundSticker sound='/media/portada/menu.mp3' img={
                                <a href='/#menu'>
                                    <MenuStickers src={window.location.origin + '/media/stickers/menu.png'} className='animate-menu' style={{position:'absolute', top:'2vh', left:'15vw', width:'26vw'}} />
                                </a>} 
                            />
                            <SoundSticker sound='/media/portada/camera.mp3' img={
                                <MenuStickers src={window.location.origin + '/media/stickers/camera.png'} onClick={cameraHandler} className='animate-live' style={{position:'absolute', top:'23vh', left:'63vw', width:'21vw'}}/>} 
                            />
                            <SoundSticker sound='/media/portada/friend.mp3' img={
                                <a href="/#Friend-Gau">
                                    <MenuStickers src={window.location.origin + '/media/stickers/friends.png'} className='animate-live' style={{position:'absolute', top:'0vh', left:'65vw', width:'20vw'}}/>
                                </a>}
                            />
                            {/* <SoundSticker sound='/media/portada/shop.mp3' img={
                                <a href='/#shop'>
                                    <MenuStickers src={window.location.origin + '/media/stickers/shop.png'} className='animate-live' style={{position:'absolute', bottom:'22vh', left:'34vw', width:'20vw'}}/>
                                </a>} 
                            /> */}
                            <SoundSticker sound='/media/portada/where.mp3' img={
                                <a href='/#where'>
                                    <MenuStickers src={window.location.origin + '/media/stickers/where.png'} className='animate-where' style={{position:'absolute', bottom:'5vh', left:'10vw', width:"15vw"}}/>
                                </a>}
                            />
                            <SoundSticker sound='/media/portada/family.mp3' img={
                                <a href='/#family-form'>
                                    <MenuStickers src={window.location.origin + '/media/stickers/family.png'} className='animate-live' style={{position:'absolute', bottom:'0vh', left:'35vw', transform:'rotate(-4.78deg)', animationDelay:'1.4s', width:'23vw'}}/>
                                </a>} 
                            />
                        </MenuItemBox>
                    </MainMenuBox>
            { camera &&  <div style={{position:'absolute', backgroundColor:'black', height:'100vh', width:'100vw', top:'0', left:'0'}} onClick={() => setCamera(false)}> 
                <ReactPlayer style={{position:'absolute'}} url={Kitchen} height='100%' width='100%'  playing={true} />
             </div>}   
           
        </div>
    )
}

export default MainMenu
