import React from 'react'
import useSound from 'use-sound'
import {  Cassette } from "./styles/Containers"

const SoundCassette = ({src, height, sound}) => {

    const [play, {stop}] = useSound(window.location.origin + sound , { volume: 0.5 });

    const playSound = () => {
        play();
    }

    const stopSound = () => {
        stop();
    }

    return (
      
            <Cassette src={src} onMouseEnter={playSound} onMouseLeave ={stopSound} height={height} width='auto'  />

    )
}

export default SoundCassette
